<template>
  <div class="relative h-full">


    <div class="pos-g engage_element">

      <div class="relative h-full">

        <div class="pos-g">
          <carrousel_infograph />
        </div>

        <div class="carrousel_graph_paginate_wrap w-slat">
          <carrousel_graph_paginate />
        </div>

        <div id="wrap_lang_btns_explore">
          <btns_language />  
        </div>

      </div>

    </div>
    
  </div>
</template>

<script>
import gsap from 'gsap'
import * as cheet from 'cheet.js'
import globals from '@/modules/globals'
import { EventBus } from '@/modules/event_bus'

import btns_language from '@/components/ui/btns_language'
import carrousel_graph_paginate from '@/components/ui/carrousel_graph_paginate'
import carrousel_infograph from '@/components/ui/carrousel_infograph'

var lv = {}
lv.thisVue = null

export default {
  name: "slat_explore",
  components: {
   btns_language,
   carrousel_graph_paginate,
   carrousel_infograph
  },
  data() {
    return {
      carrousel: {}
    }
  },
  mounted() {
    lv.thisVue = this
    this.carrousel_set()

    globals.carrousel_click_allowed = false
  },
  methods:{
    carrousel_set(){
      lv.carrousel_cur = 1
      lv.carrousel_total = 3
      EventBus.$on('carrousel_reset', (e) => {
        this.carrousel_reset()
      })
      EventBus.$on('graph_carrousel_start', (e) => {
        this.carrousel_start()
      })
      EventBus.$on('carrousel_pause', (e) => {
        this.carrousel_pause()
      })
      EventBus.$on('carrousel_resume', (e) => {
        this.carrousel_resume()
      })
      EventBus.$on('carrousel_circle_done', (e) => {
        lv.nxt = this.carrousel_next(e)
        this.carrousel_nav(lv.nxt)
      })      
      EventBus.$on('carrousel_clicked', (e) => {
        this.carrousel_pause()
        EventBus.$emit('carrousel_reset')
        this.carrousel_nav(e)
      })      

      this.carrousel_allow()
    },
    carrousel_reset(){
      this.carrousel_shield()
      lv.carrousel_cur = 1
      EventBus.$emit('carrousel_circle_reset', lv.carrousel_cur)
    },
    carrousel_start(){
      this.carrousel_shield()
      return
      EventBus.$emit('carrousel_circle_play', lv.carrousel_cur)
    },
    carrousel_pause(){
      this.carrousel_shield()
      return
      EventBus.$emit('carrousel_circle_pause', lv.carrousel_cur)
    },
    carrousel_resume(){
      this.carrousel_shield()
      return
      EventBus.$emit('carrousel_circle_resume', lv.carrousel_cur)
    },
    carrousel_nav(w){
      this.carrousel_shield()
      lv.carrousel_cur = w
      return
      EventBus.$emit('carrousel_circle_play', lv.carrousel_cur)
    },
    carrousel_next(w){
      this.carrousel_shield()
      lv.n = w + 1
      if(lv.n > lv.carrousel_total){
        lv.n = 1
      }
      return lv.n
    },
    carrousel_shield(){
      globals.carrousel_click_allowed = false
      gsap.delayedCall(1, this.carrousel_allow)
    },
    carrousel_allow(){
      globals.carrousel_click_allowed = true
    }
  }
};

cheet('c s t a r t', function () {
    EventBus.$emit('carrousel_start')
})
cheet('c s t o p', function () {
    EventBus.$emit('carrousel_pause')
})
cheet('c r e s u m e', function () {
    EventBus.$emit('carrousel_resume')
})
cheet('c r e s e t', function () {
    EventBus.$emit('carrousel_reset')
})

</script>

<style>
.carrousel_graph_paginate_wrap{
  position: absolute;
  bottom: 46px;
  left:0px;
}
#wrap_lang_btns_explore{
  position: absolute;
  bottom:46px;
  left:37px;
}

</style>
