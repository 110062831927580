
let util = {
    array_shuffle(arra1){
        var ctr = arra1.length, temp, index
        while (ctr > 0) {
            index = Math.floor(Math.random() * ctr)
            ctr--
            temp = arra1[ctr]
            arra1[ctr] = arra1[index]
            arra1[index] = temp
        }
        return arra1
    },
    getOffset(el) {
        var rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
    }
}

export default util
