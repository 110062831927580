<template>
  <div class="pos-g"> 
      <!--          
      <div class="absolute" style="border:1px solid rgba(255,255,255,0.2); top:60px; left:60px; width:1600px; height:800px; display:none;" id="indicate_chart_dimensions"></div> 
      <div class="absolute" style="border:1px solid rgba(255,255,255,0.2); top: 64px; left: 64px; width: 1594px; height: 799px; display:none;" id="indicate_chart_dimensions"></div> 
      -->

      <div class="wrapmap pointer-events-none" id="mapwrapper">
        <img src="@/assets/img/map_nl.png" />
      </div>

      <div id="canvas_wrap"></div>

      <!--  
      <div id="testline"></div>             
      -->

  </div>
</template>

<script>
import { EventBus } from '@/modules/event_bus'
import util from '@/modules/util'
import globals from '@/modules/globals'
import * as cheet from 'cheet.js'

import * as PIXI from 'pixi.js'
import { Viewport } from 'pixi-viewport'

import gsap from 'gsap'
import { PixiPlugin } from 'gsap/PixiPlugin';
gsap.registerPlugin(PixiPlugin)
PixiPlugin.registerPIXI(PIXI)

import * as Stats from 'stats.js'

var lv = {}
lv.thisVue = null
lv.crosses_data = null
lv.persons_total = 0
var spd = {}

export default {
  name: "canvas_main",
  props: [],
  data() {
    return {
    }
  },
  mounted() {
    lv.thisVue = this

    loadNextImage()

    pixify()

    fetch('json/data/crosses.json')
    .then(response => response.json())
    .then(function(data){
      //console.log('crosses.json')
      //console.log(data)
      //console.log(data['deceased_days_since_1930'])
      lv.crosses_data = data
      lv.persons_total = data.total
      lv.persons_ids = data.ids
      spread()
    })
  }
}

function spread(){

    geos_to_pixels()

    cross_container()
    ticker()
    /*
    cnt_reset()
    */

    positions_calc()

}


var app = null
var stats = null

function pixify(){

  if(globals.fps_show){
    stats = new Stats();
    stats.showPanel( 0 )
    document.body.appendChild( stats.dom )
    stats.domElement.style.top = 'auto'
    stats.domElement.style.bottom = '0px'
  }

  app = new PIXI.Application({
    width: 1920, height: 1080, backgroundColor: 0x141d1c, resolution: window.devicePixelRatio || 1,
  })

  document.getElementById('canvas_wrap').appendChild(app.view)

  //PIXI.utils.destroyTextureCache
  //PIXI.utils.clearTextureCache

  viewportControls()

}

var viewport

function viewportControls(){
  viewport = new Viewport({
    screenWidth: 1920,
    screenHeight: 1080,
    worldWidth: 1920,
    worldHeight: 1080,

    interaction: app.renderer.plugins.interaction
  })
  app.stage.addChild(viewport)
  viewport
    //.drag()
    //.pinch()
    //.wheel()
    .decelerate()
    .clampZoom(
      {
        minScale: 0.05,
        maxScale: 90
      }
    )
    //.on('drag-end', (e) => console.log(e))
    //.on('drag-end', (e) => console.log('x: ' + e.screen.x + ' y:' + e.screen.y + ' scale: ' + viewport.scale._x))
}

lv.nodes_total = 0
lv.sprite_mode = 'cross'

lv.halfWidth = 960
lv.halfHeight = 540

lv.crosses = {}
lv.cross_move = false
lv.cross_sprites
lv.cross_total = 0
function cross_container(){
  /*
  viewport.animate({
      time: 2000,
      ease: 'easeInOutCubic',
      scale: 0.6,
      position: {x: 960, y: 540}
    }
  )
  */
  
  lv.cross_total = lv.persons_total
  //lv.cross_total = 5000
  lv.cross_sprites = new PIXI.ParticleContainer(lv.persons_total, {
    scale: true,
    position: true,
    tint: true,
  })
  viewport.addChild(lv.cross_sprites)
  cross_sprites()

  //lv.cross_move = true
}
spd.speed_global_default = 50
spd.speed_global = spd.speed_global_default
function cross_sprites(){
  lv.sprite_mode = 'cross'
  for (let i = 0; i < lv.cross_total; i++) {
    lv.mid = lv.persons_ids[i]
    lv.crosses[lv.mid] = PIXI.Sprite.from(require('../assets/img/cross_3.png'))
    sprite_visual(lv.crosses[lv.mid])
    lv.crosses[lv.mid].anchor.set(0.5)
    lv.crosses[lv.mid].direction = Math.random() * Math.PI * 2
    //lv.crosses[lv.mid].turningSpeed = Math.random() - 0.8
    lv.crosses[lv.mid].turningSpeed = Math.random() - 1.5
    lv.crosses[lv.mid].speed = (2 + Math.random() * 2) * 0.2
    lv.crosses[lv.mid].offset = Math.random() * 100;

    lv.cross_sprites.addChild(lv.crosses[lv.mid])
  }
  //console.log('lv.crosses')
  //console.log(lv.crosses)
  
}

function cross_cloud_move(){
    for (let i = 0; i < lv.cross_total; i++) {
      lv.mid = lv.persons_ids[i]
      /*
      lv.crosses[lv.mid].scale.y *= 1.005
      lv.crosses[lv.mid].scale.x *= 1.005
      */
      lv.crosses[lv.mid].direction += lv.crosses[lv.mid].turningSpeed * 0.01;
      lv.crosses[lv.mid].x += Math.sin(lv.crosses[lv.mid].direction) * (lv.crosses[lv.mid].speed * spd.speed_global * lv.crosses[lv.mid].scale.y)
      lv.crosses[lv.mid].y += Math.cos(lv.crosses[lv.mid].direction) * (lv.crosses[lv.mid].speed * spd.speed_global * lv.crosses[lv.mid].scale.y)
    }
    //lv.cross_sprites.x -= 0.5


}

function ticker(){
  app.ticker.add(() => {

    if(globals.fps_show){
      stats.begin()
    }

    if(lv.cross_move){
      cross_cloud_move()
    }

    if(globals.fps_show){
      stats.end()
    }

  })
}

function sprite_visual(sprite){
  lv.spread = 5
  if(lv.sprite_mode == 'name'){
    lv.spread = 3
  }
  lv.xmax = 1920 * lv.spread
  lv.ymax = 1080

  /*
  sprite.x = Math.random() * lv.xmax
  sprite.y = Math.random() * lv.ymax
  sprite.scale.set(getScale())
  sprite.tint = getTint()
  */
  //

  sprite.x = 900 + (Math.random() * 80)
  sprite.y = 460 + (Math.random() * 80)
  sprite.scale.set(0.06)
  sprite.tint = 0xffffff
}

lv.cnt_tint = 0
lv.cnt_scale = 0
function cnt_reset(){
  lv.cnt_tint = 0
  lv.cnt_scale = 0
}

function getTint(){
  lv.total = lv[lv.sprite_mode + '_total']
  lv.tint = Math.random() * 0x606060
  lv.cnt_tint++
  lv.tint = 0xffffff
  if(lv.cnt_tint < lv.total * 0.8){
    lv.tint = 0x999999
  }
  if(lv.cnt_tint < lv.total * 0.6){
    lv.tint = 0x777777
  }
  if(lv.cnt_tint < lv.total * 0.4){
    lv.tint = 0x555555
  }
  if(lv.cnt_tint < lv.total * 0.2){
    lv.tint = 0x333333
  }
  // 0x9ddad3 0x7caca7 0x51736f 0x2f4341
  //lv.tint = 0xffffff
  return lv.tint
}

function getScale(){
  lv.total = lv[lv.sprite_mode + '_total']
  lv.cnt_scale++
  lv.scale = 1
  if(lv.cnt_scale < lv.total * 0.8){
    lv.scale = 0.8
  }
  if(lv.cnt_scale < lv.total * 0.6){
    lv.scale = 0.64
  }
  if(lv.cnt_scale < lv.total * 0.4){
    lv.scale = 0.47
  }
  if(lv.cnt_scale < lv.total * 0.2){
    lv.scale = 0.3
  }
  lv.scale *= 0.7
  //lv.scale = 1
  return lv.scale
}


//////////////////////////////////////// helper funcions

lv.delay = 0
lv.delays = []
lv.nr = 0
while(lv.nr < lv.cross_total){
  lv.delay += 0.00005
  lv.delays.push(lv.delay)
  lv.nr++
}

function numberWithDots(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".");
}

//////////////////////////////////////// event funcions

lv.x_incr = 11
lv.y_incr = 14
lv.x_row = lv.x_incr * 260
function cross_raster(){

  lv.cross_move = false
  //gsap.delayedCall(2,set_cross_raster)
  cross_raster_size_tint()
}
function cross_raster_size_tint(){
  lv.nr = 0
  while(lv.nr < lv.cross_total){
    lv.mid = lv.persons_ids[lv.nr]
    gsap.to(lv.crosses[lv.mid], {pixi: {scale: 0.2, tint: 0xffffff}, duration: 1, ease:'power4.inOut'})
    lv.nr++
  }  

  gsap.delayedCall(0.9, cross_raster_viewport)
}

function cross_raster_viewport(){
  viewport.animate({
      time: 2000,
      ease: 'easeInOutCubic',
      scale: 0.6,
      position: {x: 1400, y: 850}
    }
  )
  gsap.to(lv.cross_sprites, {pixi: {x: 0}, duration: 2, ease:'power2.inOut'})
  gsap.delayedCall(1, set_cross_raster_positions)
}

function set_cross_raster_positions(){

  lv.x = 0
  lv.y = 0

  lv.delays = util.array_shuffle(lv.delays)
  
  lv.nr = 0
  while(lv.nr < lv.cross_total){
      lv.mid = lv.persons_ids[lv.nr]

    lv.x += lv.x_incr
    if(lv.x >= lv.x_row){
      lv.x = lv.x_incr
      lv.y += lv.y_incr
    }

    //lv.crosses[lv.mid].scale.set(0.2)
    //lv.crosses[lv.mid].tint = 0xffffff
    //lv.crosses[lv.mid].x = lv.x
    //lv.crosses[lv.mid].y = lv.y

    lv.delay = lv.delays[lv.nr]

    gsap.to(lv.crosses[lv.mid], {pixi: {y: lv.y, x: lv.x, scale: 0.2, tint: 0xffffff}, delay: lv.delay, duration: 2, ease:'power4.inOut'})

    lv.nr++
  }  
}


lv.pos_y_base = 777
lv.pos_x_base = 93

lv.cross_age_years_calculated = false
lv.cross_age_years = {}
lv.cross_age_years['positions'] = {}
lv.cross_age_years['stats'] = {}
function cross_age_years_calc(){

  lv.persons_ids = util.array_shuffle(lv.persons_ids)

  //lv.cross_move = false
  lv.pos_y_base = 777
  lv.pos_x_base = 93
  
  lv.has_age = 0
  lv.ages_remem = []
  lv.randinc = 1
  lv.count_no_age = 0;

  lv.leftovers = []
  lv.age_stats = {}
  lv.age_stats['years'] = {}
  lv.age_stats['age'] = {}

  lv.chkages = []

  lv.graph_height = 662
  lv.highest_count = 2536 // could/should become dynamic
  lv.graph_y_incr = lv.graph_height / lv.highest_count
  lv.graph_y_incr *= 1.03
  lv.pos_y_lowest = 1080

  lv.nr = 0
  while(lv.nr < lv.cross_total){
    lv.mid =  lv.persons_ids[lv.nr]
    lv.last_ageyears = 0
    lv.ageyears = parseInt(lv.crosses_data['age'][lv.mid]['age'])
    lv.haveage = false
    if(lv.ageyears != -1){
      lv.haveage = true
      lv.maxyears = 110
      if(lv.ageyears < lv.maxyears){
        lv.has_age++
        lv.pos_age = lv.pos_x_base + (lv.ageyears * 16.933)
        //lv.pos_age += Math.random() * 8
        lv.pos_y = lv.pos_y_base 
        if(lv.ages_remem.includes(lv.ageyears)){
          lv.amount = lv.ages_remem.filter(x => x == lv.ageyears).length
          //lv.randinc *= 0.01
          //lv.pos_y -= 10 * lv.amount + Math.random() * lv.randinc
          lv.y_step = Math.floor(lv.graph_y_incr * lv.amount)
          lv.pos_y -= lv.y_step
          if(lv.pos_y < lv.pos_y_lowest){
            lv.pos_y_lowest = lv.pos_y
          }
          lv.age_stats['years'][lv.ageyears]++
        }else{
          lv.age_stats['years'][lv.ageyears] = 1
          lv.age_stats['age'][lv.ageyears] = lv.crosses_data['age'][lv.mid]['age']
        }
        lv.ages_remem.push(lv.ageyears)

        lv.cross_age_years['positions'][lv.mid] = {x: lv.pos_age, y: lv.pos_y}

        lv.dly = (Math.floor(Math.random() * 100) + 1) * 0.001
        //gsap.to(lv.crosses[lv.mid], {pixi: {scale: 0.06, tint: 0xffffff, x: lv.pos_age, y: lv.pos_y }, delay: lv.dly, duration: 2.4, ease:'power4.inOut'})
      }else{
        lv.haveage = false
      }
    }else{
      lv.haveage = false
    }
    if(!lv.haveage){
      lv.count_no_age++
      lv.lo = {}
      lv.lo['mid'] = lv.mid
      lv.leftovers.push(lv.lo)
    }
    lv.nr++
  }  

  lv.lol = lv.leftovers.length
  lv.nr = 0
  lv.ratio = lv.lol/(Math.PI*2)
  while(lv.nr < lv.lol){
    lv.rad = Math.floor(Math.random() * 20) + 1
    lv.leftovers[lv.nr]['x'] = 1460 + lv.rad * Math.cos(lv.nr/lv.ratio)
    lv.leftovers[lv.nr]['y'] = 120 + lv.rad * Math.sin(lv.nr/lv.ratio)
    lv.nr++
  }

  lv.nr = 0
  while(lv.nr < lv.lol){

    lv.cross_age_years['positions'][lv.leftovers[lv.nr]['mid']] = {x: lv.leftovers[lv.nr]['x'], y: lv.leftovers[lv.nr]['y']}

    lv.dly = (Math.floor(Math.random() * 100) + 1) * 0.001
    //gsap.to(lv.crosses[lv.leftovers[lv.nr]['mid']], {pixi: {scale: 0.06, tint: 0xffffff, x: lv.leftovers[lv.nr]['x'], y: lv.leftovers[lv.nr]['y'] }, delay: lv.dly, duration: 2.4, ease:'power4.inOut'})
    lv.nr++
  }

  lv.arr = Object.keys( lv.age_stats['years'] ).map(function ( key ) { return lv.age_stats['years'][key]; })
  lv.min = Math.min.apply( null, lv.arr )
  lv.max = Math.max.apply( null, lv.arr )
  lv.age_max = Object.keys(lv.age_stats['years']).find(key => lv.age_stats['years'][key] === lv.max)

  lv.stat_string_unknown = 'persons with unknow age: ' + lv.count_no_age
  lv.stat_string_result = 'highest age count: ' + lv.max + ' (years ' + lv.age_max + ')'

  lv.cross_age_years['stats']['total_unknown'] = lv.count_no_age
  lv.cross_age_years['stats']['peak'] = lv.max
  lv.cross_age_years['stats']['peak_count'] = lv.age_max
  lv.cross_age_years['stats']['stat_string_count'] = lv.stat_string_unknown
  lv.cross_age_years['stats']['stat_string_count_data'] = lv.stat_string_result

  //console.log(lv.stat_string_unknown)
  //console.log(lv.stat_string_result)

  lv.cross_age_years_calculated = true

  lv.t = {}
  lv.t.peak = lv.pos_y_lowest
  lv.t.high = numberWithDots(lv.max)
  lv.t.unknown = numberWithDots(lv.count_no_age)
  EventBus.$emit('calc_age', lv.t)

}

function cross_age_years_animate(){
  
  lv.cross_move = false
  lv.persons_ids_shuffled = JSON.parse(JSON.stringify(lv.persons_ids))
  lv.persons_ids_shuffled = util.array_shuffle(lv.persons_ids_shuffled)

  lv.nr = 0
  while(lv.nr < lv.cross_total){
    lv.mid =  lv.persons_ids[lv.nr]
    lv.vid =  lv.persons_ids_shuffled[lv.nr]
    lv.cx = 0
    lv.cy = 0
    if(lv.cross_age_years['positions'][lv.mid]){
      lv.cx = lv.cross_age_years['positions'][lv.mid].x
      lv.cy = lv.cross_age_years['positions'][lv.mid].y
    }
    lv.dly = (Math.floor(Math.random() * 100) + 1) * 0.001
    gsap.to(lv.crosses[lv.vid], {pixi: {scale: 0.06, tint: 0xffffff, x: lv.cx, y: lv.cy }, delay: lv.dly, duration: 2.4, ease:'power4.inOut'})
    lv.nr++
  }
  console.log(lv.cross_age_years['stats']['stat_string_count'])
  console.log(lv.cross_age_years['stats']['stat_string_count_data'])
}

function cross_age_years_show(){

  if(!lv.cross_age_years_calculated){
    cross_age_years_calc()
    return
  }
  cross_age_years_animate()
}

lv.cross_age_days_calculated = false
lv.cross_age_days = {}
lv.cross_age_days['positions'] = {}
lv.cross_age_days['stats'] = {}
function cross_age_days_calc(){

  //lv.cross_move = false
  
  lv.has_age = 0
  lv.ages_remem = []
  lv.randinc = 1
  lv.count_no_age = 0;

  lv.leftovers = []
  lv.age_stats = {}
  lv.age_stats['days'] = {}
  lv.age_stats['age'] = {}

  lv.nr = 0
  while(lv.nr < lv.cross_total){
    lv.mid =  lv.persons_ids[lv.nr]
    lv.last_agedays = 0
    lv.agedays = parseInt(lv.crosses_data['age'][lv.mid]['days'])
    lv.haveage = false
    if(lv.agedays != -1){
      lv.haveage = true
      lv.agedays = Math.floor(lv.agedays / 12)
      lv.maxdays = Math.floor(35600 / 12)
      if(lv.agedays < lv.maxdays){
        lv.has_age++
        lv.pos_age = lv.pos_x_base + (Number(lv.agedays) * 0.575)
        //lv.pos_age += Math.random() * 8
        lv.pos_y = lv.pos_y_base - 7
        if(lv.ages_remem.includes(lv.agedays)){
          lv.amount = lv.ages_remem.filter(x => x == lv.agedays).length
          //lv.randinc *= 0.01
          //lv.pos_y -= 10 * lv.amount + Math.random() * lv.randinc
          lv.pos_y -= Math.floor(8.1 * lv.amount)
          lv.age_stats['days'][lv.agedays]++
        }else{
          lv.age_stats['days'][lv.agedays] = 1
          lv.age_stats['age'][lv.agedays] = lv.crosses_data['age'][lv.mid]['age']
        }
        lv.ages_remem.push(lv.agedays)

        lv.cross_age_days['positions'][lv.mid] = {x: lv.pos_age, y: lv.pos_y}

        lv.dly = (Math.floor(Math.random() * 100) + 1) * 0.001
        //gsap.to(lv.crosses[lv.mid], {pixi: {scale: 0.18, tint: 0xffffff, x: lv.pos_age, y: lv.pos_y }, delay: lv.dly, duration: 2.4, ease:'power4.inOut'})
      }else{
        lv.haveage = false
      }
    }else{
      lv.haveage = false
    }
    if(!lv.haveage){
      lv.count_no_age++
      lv.lo = {}
      lv.lo['mid'] = lv.mid
      lv.leftovers.push(lv.lo)
    }
    lv.nr++
  }  

  lv.lol = lv.leftovers.length
  lv.nr = 0
  lv.ratio = lv.lol/(Math.PI*2)
  while(lv.nr < lv.lol){
    lv.rad = Math.floor(Math.random() * 20) + 1
    lv.leftovers[lv.nr]['x'] = 1500 + lv.rad * Math.cos(lv.nr/lv.ratio)
    lv.leftovers[lv.nr]['y'] = 150 + lv.rad * Math.sin(lv.nr/lv.ratio)
    lv.nr++
  }

  lv.nr = 0
  while(lv.nr < lv.lol){

    lv.cross_age_days['positions'][lv.leftovers[lv.nr]['mid']] = {x: lv.leftovers[lv.nr]['x'], y: lv.leftovers[lv.nr]['y']}

    lv.dly = (Math.floor(Math.random() * 100) + 1) * 0.001
    //gsap.to(lv.crosses[lv.leftovers[lv.nr]['mid']], {pixi: {scale: 0.06, tint: 0xffffff, x: lv.leftovers[lv.nr]['x'], y: lv.leftovers[lv.nr]['y'] }, delay: lv.dly, duration: 2.4, ease:'power4.inOut'})
    lv.nr++
  }

  lv.arr = Object.keys( lv.age_stats['days'] ).map(function ( key ) { return lv.age_stats['days'][key]; })
  lv.min = Math.min.apply( null, lv.arr )
  lv.max = Math.max.apply( null, lv.arr )
  lv.age_max = Object.keys(lv.age_stats['days']).find(key => lv.age_stats['days'][key] === lv.max)

  lv.stat_string_unknown = 'persons with unknow age: ' + lv.count_no_age
  lv.stat_string_result = 'highest age count: ' + lv.max + ' (days ' + lv.age_max + ')' + '(age ' + lv.age_stats['age'][lv.age_max] + ' )'

  lv.cross_age_days['stats']['total_unknown'] = lv.count_no_deceased
  lv.cross_age_days['stats']['peak'] = lv.max
  lv.cross_age_days['stats']['peak_count'] = lv.age_max
  lv.cross_age_days['stats']['stat_string_count'] = lv.stat_string_unknown
  lv.cross_age_days['stats']['stat_string_count_data'] = lv.stat_string_result

  //console.log(lv.stat_string_unknown)
  //console.log(lv.stat_string_result)

  lv.cross_age_days_calculated = true
  
}

function cross_age_days_animate(){

  lv.cross_move = false

  lv.nr = 0
  while(lv.nr < lv.cross_total){
    lv.mid =  lv.persons_ids[lv.nr]
    lv.cx = 0
    lv.cy = 0
    if(lv.cross_age_days['positions'][lv.mid]){
      lv.cx = lv.cross_age_days['positions'][lv.mid].x
      lv.cy = lv.cross_age_days['positions'][lv.mid].y
    }
    lv.dly = (Math.floor(Math.random() * 100) + 1) * 0.001
    gsap.to(lv.crosses[lv.mid], {pixi: {scale: 0.18, tint: 0xffffff, x: lv.cx, y: lv.cy }, delay: lv.dly, duration: 2.4, ease:'power4.inOut'})
    lv.nr++
  }
  console.log(lv.cross_age_days['stats']['stat_string_count'])
  console.log(lv.cross_age_days['stats']['stat_string_count_data'])
}

function cross_age_days_show(){
  if(!lv.cross_age_days_calculated){
    cross_age_days_calc()
    return
  }
  cross_age_days_animate()
}

lv.cross_deceased_calculated = false
lv.cross_deceased = {}
lv.cross_deceased['positions'] = {}
lv.cross_deceased['stats'] = {}
function cross_deceased_calc(){

  //lv.cross_move = false
  
  lv.has_deceased = 0
  lv.deceased_remem = []
  lv.randinc = 1
  lv.count_no_deceased = 0

  lv.lowest_dec_nr = 10000
  lv.nr = 0
  while(lv.nr < lv.cross_total){
    lv.mid =  lv.persons_ids[lv.nr]
    lv.d = parseInt(lv.crosses_data['deceased'][lv.mid]['days'])
    if(lv.d != 0){
      if(lv.d < lv.lowest_dec_nr){
        lv.lowest_dec_nr = lv.d
      }
    }
    lv.nr++
  }
  lv.leftovers = []

  lv.deceased_stats = {}
  lv.deceased_stats['days'] = {}
  lv.deceased_stats['dates'] = {}

  lv.nr = 0
  while(lv.nr < lv.cross_total){
    lv.mid =  lv.persons_ids[lv.nr]
    lv.last_deceaseddays = 0
    lv.deceaseddays = parseInt(lv.crosses_data['deceased'][lv.mid]['days'])
    lv.havedays = false
    if(lv.deceaseddays != 0){
      lv.havedays = true
      lv.maxdays = 7000
      if(lv.deceaseddays < lv.maxdays){
        lv.has_deceased++
        lv.pos_deceased = lv.pos_x_base + Math.floor(((lv.deceaseddays - lv.lowest_dec_nr) * 0.49))
        //lv.pos_deceased += Math.random() * 3
        lv.pos_y = lv.pos_y_base 
        if(lv.deceased_remem.includes(lv.deceaseddays)){
          lv.amount = lv.deceased_remem.filter(x => x == lv.deceaseddays).length
          //lv.randinc *= 0.01
          //lv.pos_y -= 1 * lv.amount + Math.random() * lv.randinc
          lv.pos_y -= Math.floor(0.985 * lv.amount)
          lv.deceased_stats['days'][lv.deceaseddays]++
        }else{
          lv.deceased_stats['days'][lv.deceaseddays] = 1
          lv.deceased_stats['dates'][lv.deceaseddays] = lv.crosses_data['deceased'][lv.mid]['date']
        }
        lv.deceased_remem.push(lv.deceaseddays)

        lv.cross_deceased['positions'][lv.mid] = {x: lv.pos_deceased, y: lv.pos_y}

        lv.dly = (Math.floor(Math.random() * 100) + 1) * 0.001
        //gsap.to(lv.crosses[lv.mid], {pixi: {scale: 0.06, tint: 0xffffff, x: lv.pos_deceased, y: lv.pos_y }, delay: lv.dly, duration: 2.4, ease:'power4.inOut'})
      }else{
        lv.havedays = false
      }
    }else{
      lv.havedays = false
    }
    if(!lv.havedays){
      lv.count_no_deceased++
      lv.lo = {}
      lv.lo['mid'] = lv.mid
      lv.leftovers.push(lv.lo)
    }
    lv.nr++
  }  

  lv.lol = lv.leftovers.length
  lv.nr = 0
  lv.ratio = lv.lol/(Math.PI*2)
  while(lv.nr < lv.lol){
    lv.rad = Math.floor(Math.random() * 20) + 1
    lv.leftovers[lv.nr]['x'] = 1500 + lv.rad * Math.cos(lv.nr/lv.ratio)
    lv.leftovers[lv.nr]['y'] = 150 + lv.rad * Math.sin(lv.nr/lv.ratio)
    lv.nr++
  }

  lv.nr = 0
  while(lv.nr < lv.lol){

    lv.cross_deceased['positions'][lv.leftovers[lv.nr]['mid']] = {x: lv.leftovers[lv.nr]['x'], y: lv.leftovers[lv.nr]['y']}

    lv.dly = (Math.floor(Math.random() * 100) + 1) * 0.001
    //gsap.to(lv.crosses[lv.leftovers[lv.nr]['mid']], {pixi: {scale: 0.06, tint: 0xffffff, x: lv.leftovers[lv.nr]['x'], y: lv.leftovers[lv.nr]['y'] }, delay: lv.dly, duration: 2.4, ease:'power4.inOut'})
    lv.nr++
  }


  lv.arr = Object.keys( lv.deceased_stats['days'] ).map(function ( key ) { return lv.deceased_stats['days'][key]; })
  lv.min = Math.min.apply( null, lv.arr )
  lv.max = Math.max.apply( null, lv.arr )
  lv.deceased_max = Object.keys(lv.deceased_stats['days']).find(key => lv.deceased_stats['days'][key] === lv.max)

  lv.stat_string_unknown = 'persons with unknow deceased date: ' + lv.count_no_deceased
  lv.stat_string_result = 'highest death count on one day: ' + lv.max + ' (days ' + lv.deceased_max + ')' + ' (date ' + lv.deceased_stats['dates'][lv.deceased_max] + ')'

  lv.cross_deceased['stats']['total_unknown'] = lv.count_no_deceased
  lv.cross_deceased['stats']['peak'] = lv.max
  lv.cross_deceased['stats']['peak_count'] = lv.deceased_max
  lv.cross_deceased['stats']['peak_count_data'] = lv.deceased_stats['dates'][lv.deceased_max]
  lv.cross_deceased['stats']['stat_string_count'] = lv.stat_string_unknown
  lv.cross_deceased['stats']['stat_string_count_data'] = lv.stat_string_result

  //console.log(lv.stat_string_unknown)
  //console.log(lv.stat_string_result)

  lv.cross_deceased_calculated = true
  
}

function cross_deceased_animate(){

  lv.cross_move = false

  lv.nr = 0
  while(lv.nr < lv.cross_total){
    lv.mid =  lv.persons_ids[lv.nr]
    lv.cx = 0
    lv.cy = 0
    if(lv.cross_deceased['positions'][lv.mid]){
      lv.cx = lv.cross_deceased['positions'][lv.mid].x
      lv.cy = lv.cross_deceased['positions'][lv.mid].y
    }
    lv.dly = (Math.floor(Math.random() * 100) + 1) * 0.001
    gsap.to(lv.crosses[lv.mid], {pixi: {scale: 0.06, tint: 0xffffff, x: lv.cx, y: lv.cy }, delay: lv.dly, duration: 2.4, ease:'power4.inOut'})
    lv.nr++
  }
  console.log(lv.cross_deceased['stats']['stat_string_count'])
  console.log(lv.cross_deceased['stats']['stat_string_count_data'])
}

function cross_deceased_show(){
  if(!lv.cross_deceased_calculated){
    cross_deceased_calc()
    return
  }
  cross_deceased_animate()
}

lv.cross_deceased_months_calculated = false
lv.cross_deceased_months = {}
lv.cross_deceased_months['positions'] = {}
lv.cross_deceased_months['stats'] = {}
function cross_deceased_months_calc(){

  lv.persons_ids = util.array_shuffle(lv.persons_ids)

  //lv.cross_move = false
  lv.pos_y_base = 777
  lv.pos_x_base = 88
  
  lv.has_deceased = 0
  lv.deceased_remem = []
  lv.randinc = 1
  lv.count_no_deceased = 0

  lv.lowest_dec_nr = 10000
  lv.nr = 0
  while(lv.nr < lv.cross_total){
    lv.mid =  lv.persons_ids[lv.nr]
    lv.d = parseInt(lv.crosses_data['deceased'][lv.mid]['months'])
    if(lv.d != 0){
      if(lv.d < lv.lowest_dec_nr){
        lv.lowest_dec_nr = lv.d
      }
    }
    lv.nr++
  }
  lv.leftovers = []

  lv.deceased_stats = {}
  lv.deceased_stats['months'] = {}
  lv.deceased_stats['date_month'] = {}
  lv.deceased_stats['dates'] = {}

  lv.graph_height = 662
  lv.highest_count = 4702 // could/should become dynamic
  lv.graph_y_incr = lv.graph_height / lv.highest_count
  lv.graph_y_incr *= 1.03
  lv.pos_y_lowest = 1080

  lv.nr = 0
  while(lv.nr < lv.cross_total){
    lv.mid =  lv.persons_ids[lv.nr]
    lv.last_deceasedmonths = 0
    lv.deceasedmonths = parseInt(lv.crosses_data['deceased'][lv.mid]['months'])
    lv.havemonths = false
    if(lv.deceasedmonths != 0){
      lv.havemonths = true
      lv.maxmonths = 1200
      if(lv.deceasedmonths < lv.maxmonths){
        lv.has_deceased++
        lv.pos_deceased = lv.pos_x_base + Math.floor(((lv.deceasedmonths - lv.lowest_dec_nr) * 14.252))
        //lv.pos_deceased += Math.random() * 3
        lv.pos_y = lv.pos_y_base 
        if(lv.deceased_remem.includes(lv.deceasedmonths)){
          lv.amount = lv.deceased_remem.filter(x => x == lv.deceasedmonths).length
          //lv.randinc *= 0.01
          //lv.pos_y -= 1 * lv.amount + Math.random() * lv.randinc
          lv.pos_y -= Math.floor(lv.graph_y_incr * lv.amount)
          lv.deceased_stats['months'][lv.deceasedmonths]++
        }else{
          lv.deceased_stats['months'][lv.deceasedmonths] = 1
          //lv.deceased_stats['dates'][lv.deceasedmonths] = lv.crosses_data['deceased'][lv.mid]['date']
          lv.deceased_stats['date_month'][lv.deceasedmonths] = lv.crosses_data['deceased'][lv.mid]['date_month']
        }
        lv.deceased_remem.push(lv.deceasedmonths)

        lv.cross_deceased_months['positions'][lv.mid] = {x: lv.pos_deceased, y: lv.pos_y}

        lv.dly = (Math.floor(Math.random() * 100) + 1) * 0.001
        //gsap.to(lv.crosses[lv.mid], {pixi: {scale: 0.06, tint: 0xffffff, x: lv.pos_deceased, y: lv.pos_y }, delay: lv.dly, duration: 2.4, ease:'power4.inOut'})
      }else{
        lv.havemonths = false
      }
    }else{
      lv.havemonths = false
    }
    if(!lv.havemonths){
      lv.count_no_deceased++
      lv.lo = {}
      lv.lo['mid'] = lv.mid
      lv.leftovers.push(lv.lo)
    }
    lv.nr++
  }  

  lv.lol = lv.leftovers.length
  lv.nr = 0
  lv.ratio = lv.lol/(Math.PI*2)
  while(lv.nr < lv.lol){
    lv.rad = Math.floor(Math.random() * 20) + 1
    lv.leftovers[lv.nr]['x'] = 1460 + lv.rad * Math.cos(lv.nr/lv.ratio)
    lv.leftovers[lv.nr]['y'] = 120 + lv.rad * Math.sin(lv.nr/lv.ratio)
    lv.nr++
  }

  lv.nr = 0
  while(lv.nr < lv.lol){

    lv.cross_deceased_months['positions'][lv.leftovers[lv.nr]['mid']] = {x: lv.leftovers[lv.nr]['x'], y: lv.leftovers[lv.nr]['y']}

    lv.dly = (Math.floor(Math.random() * 100) + 1) * 0.001
    //gsap.to(lv.crosses[lv.leftovers[lv.nr]['mid']], {pixi: {scale: 0.06, tint: 0xffffff, x: lv.leftovers[lv.nr]['x'], y: lv.leftovers[lv.nr]['y'] }, delay: lv.dly, duration: 2.4, ease:'power4.inOut'})
    lv.nr++
  }

  lv.arr = Object.keys( lv.deceased_stats['months'] ).map(function ( key ) { return lv.deceased_stats['months'][key]; })
  lv.min = Math.min.apply( null, lv.arr )
  lv.max = Math.max.apply( null, lv.arr )
  lv.deceased_max = Object.keys(lv.deceased_stats['months']).find(key => lv.deceased_stats['months'][key] === lv.max)

  lv.stat_string_unknown = 'persons with unknow deceased date: ' + lv.count_no_deceased
  lv.stat_string_result = 'highest death count in one month: ' + lv.max + ' (months ' + lv.deceased_max + ')' + ' (date_month ' + lv.deceased_stats['date_month'][lv.deceased_max] + ')'

  lv.cross_deceased_months['stats']['total_unknown'] = lv.count_no_deceased
  lv.cross_deceased_months['stats']['peak'] = lv.max
  lv.cross_deceased_months['stats']['peak_count'] = lv.deceased_max
  lv.cross_deceased_months['stats']['peak_count_data'] = lv.deceased_stats['date_month'][lv.deceased_max]
  lv.cross_deceased_months['stats']['stat_string_count'] = lv.stat_string_unknown
  lv.cross_deceased_months['stats']['stat_string_count_data'] = lv.stat_string_result

  //console.log(lv.stat_string_unknown)
  //console.log(lv.stat_string_result)

  lv.cross_deceased_months_calculated = true

  lv.t = {}
  //lv.t.peak = lv.pos_y_lowest
  lv.t.high = numberWithDots(lv.max)
  lv.t.unknown = numberWithDots(lv.count_no_deceased)
  EventBus.$emit('calc_deceased', lv.t)

}

function cross_deceased_months_animate(){

  lv.cross_move = false
  lv.persons_ids_shuffled = JSON.parse(JSON.stringify(lv.persons_ids))
  lv.persons_ids_shuffled = util.array_shuffle(lv.persons_ids_shuffled)

  lv.nr = 0
  while(lv.nr < lv.cross_total){
    lv.mid =  lv.persons_ids[lv.nr]
    lv.vid =  lv.persons_ids_shuffled[lv.nr]
    lv.cx = 0
    lv.cy = 0
    if(lv.cross_deceased_months['positions'][lv.mid]){
      lv.cx = lv.cross_deceased_months['positions'][lv.mid].x
      lv.cy = lv.cross_deceased_months['positions'][lv.mid].y
    }
    lv.dly = (Math.floor(Math.random() * 100) + 1) * 0.001
    gsap.to(lv.crosses[lv.vid], {pixi: {scale: 0.06, tint: 0xffffff, x: lv.cx, y: lv.cy }, delay: lv.dly, duration: 2.4, ease:'power4.inOut'})
    lv.nr++
  }
  console.log(lv.cross_deceased_months['stats']['stat_string_count'])
  console.log(lv.cross_deceased_months['stats']['stat_string_count_data'])
}

function cross_deceased_months_show(){
  if(!lv.cross_deceased_months_calculated){
    cross_deceased_months_calc()
    return
  }
  cross_deceased_months_animate()
}

//////////////////////////////////////// 
function positions_calc(){
  cross_age_years_calc()
  cross_origin_calc()
  cross_deceased_months_calc()
  return
  cross_deceased_calc()
  cross_age_days_calc()
}


//////////////////////////////////////// locations

lv.map_width = 831
lv.map_height = 982

lv.map_offset_left = 130
lv.map_offset_top = 31

lv.cross_nudeg_left = 7
lv.cross_nudeg_top = 10

function geos_to_pixels(){
  for (const [key, value] of Object.entries(lv.crosses_data.origin)) {
    //console.log(`${key}: ${value}`);
    if(lv.crosses_data.origin[key].geo){
      //console.log('found' + lv.crosses_data.origin[key].geo)
      lv.latlon = JSON.parse(lv.crosses_data.origin[key].geo)
      lv.lat = lv.latlon['lat']
      lv.lon = lv.latlon['lng']
      lv.pix = latlngToScreenXY(lv.lat, lv.lon)
      lv.crosses_data.origin[key].pixels = lv.pix

      //console.log('2. have latlon: ' + lv.lat + ',' + lv.lon)
      //console.log('2. got x y: ' + lv.pix.x + ',' + lv.pix.y)
    }
  }
  //console.log(lv.crosses_data.origin)
}

lv.cross_origin_calculated = false
lv.cross_origin = {}
lv.cross_origin['positions'] = {}
lv.cross_origin['stats'] = {}
function cross_origin_calc(){

  //lv.cross_move = false
  
  lv.has_origin = 0
  lv.origin_remem = []
  lv.randinc = 1
  lv.count_no_origin = 0

  lv.leftovers = []
  lv.offchart = []

  lv.origin_stats = {}
  lv.origin_stats['place'] = {}
  lv.origin_stats['geo'] = {}

  lv.nr = 0
  while(lv.nr < lv.cross_total){
    lv.mid =  lv.persons_ids[lv.nr]
    lv.last_originxy = 0
    lv.have_geo = false
    lv.origin_geo = lv.crosses_data['origin'][lv.mid]['geo']
    if(lv.origin_geo){
      lv.have_geo = true
      lv.has_origin++

      lv.origin_x = parseInt(lv.crosses_data['origin'][lv.mid]['pixels'].x)
      lv.origin_y = parseInt(lv.crosses_data['origin'][lv.mid]['pixels'].y)
      lv.origin_place = lv.crosses_data['origin'][lv.mid]['place']

      lv.scale = 0.12

      if(lv.origin_remem.includes(lv.origin_place)){
        lv.origin_stats['place'][lv.origin_place]++
        lv.amount = lv.origin_remem.filter(x => x == lv.origin_place).length
        lv.scale = lv.scale * (lv.amount * 0.005)
      }else{
        lv.origin_stats['place'][lv.origin_place] = 1
      }
      lv.origin_remem.push(lv.origin_place)

      if(lv.origin_x < 0 || lv.origin_x > (lv.map_width + 100) || lv.origin_y < 0 || lv.origin_y > (lv.map_height + 20)){
        lv.uc = {}
        lv.uc['mid'] = lv.mid
        lv.uc['location'] = lv.crosses_data['origin'][lv.mid]['place']
        lv.offchart.push(lv.uc)
      }else{

        lv.o_x = lv.map_offset_left + lv.cross_nudeg_left + lv.origin_x
        lv.o_y = lv.map_offset_top + lv.cross_nudeg_top + lv.origin_y

        lv.cross_origin['positions'][lv.mid] = {x: lv.o_x, y: lv.o_y, scale: lv.scale}

        lv.dly = (Math.floor(Math.random() * 100) + 1) * 0.001
        //gsap.to(lv.crosses[lv.mid], {pixi: {scale: lv.scale, tint: 0xffffff, x: lv.o_x, y: lv.o_y }, delay: lv.dly, duration: 2.4, ease:'power4.inOut'})

      }

    }else{
      lv.have_geo = false
    }
    if(!lv.have_geo){
      lv.count_no_origin++
      lv.lo = {}
      lv.lo['mid'] = lv.mid
      lv.leftovers.push(lv.lo)
    }
    lv.nr++
  }  

  lv.lol = lv.leftovers.length
  lv.nr = 0
  lv.ratio = lv.lol/(Math.PI*2)
  while(lv.nr < lv.lol){
    lv.rad = Math.floor(Math.random() * 10) + 1
    lv.leftovers[lv.nr]['x'] = 1460 + lv.rad * Math.cos(lv.nr/lv.ratio)
    lv.leftovers[lv.nr]['y'] = 125 + lv.rad * Math.sin(lv.nr/lv.ratio)
    lv.leftovers[lv.nr]['scale'] = 0.1
    lv.nr++
  }
  lv.nr = 0
  while(lv.nr < lv.lol){

    lv.cross_origin['positions'][lv.leftovers[lv.nr]['mid']] = {x: lv.leftovers[lv.nr]['x'], y: lv.leftovers[lv.nr]['y'], scale: lv.leftovers[lv.nr]['scale']}

    lv.dly = (Math.floor(Math.random() * 100) + 1) * 0.001
    //gsap.to(lv.crosses[lv.leftovers[lv.nr]['mid']], {pixi: {scale: 0.1, tint: 0xffffff, x: lv.leftovers[lv.nr]['x'], y: lv.leftovers[lv.nr]['y'] }, delay: lv.dly, duration: 2.4, ease:'power4.inOut'})
    lv.nr++
  }

  lv.lol = lv.offchart.length
  lv.nr = 0
  lv.ratio = lv.lol/(Math.PI*2)
  while(lv.nr < lv.lol){
    lv.rad = Math.floor(Math.random() * 5) + 1
    lv.offchart[lv.nr]['x'] = 1460 + lv.rad * Math.cos(lv.nr/lv.ratio)
    lv.offchart[lv.nr]['y'] = 240 + lv.rad * Math.sin(lv.nr/lv.ratio)
    lv.offchart[lv.nr]['scale'] = 0.1
    lv.nr++
  }
  lv.nr = 0
  while(lv.nr < lv.lol){

    lv.cross_origin['positions'][lv.offchart[lv.nr]['mid']] = {x: lv.offchart[lv.nr]['x'], y: lv.offchart[lv.nr]['y'], scale: lv.offchart[lv.nr]['scale']}

    lv.dly = (Math.floor(Math.random() * 100) + 1) * 0.001
    //gsap.to(lv.crosses[lv.offchart[lv.nr]['mid']], {pixi: {scale: 0.1, tint: 0xffffff, x: lv.offchart[lv.nr]['x'], y: lv.offchart[lv.nr]['y'] }, delay: lv.dly, duration: 2.4, ease:'power4.inOut'})
    lv.nr++
  }

  lv.stat_string_unknown = 'persons with unknow origin: ' + lv.count_no_origin
  lv.stat_string_result = 'persons with offchart coordinates: ' + lv.offchart.length

  lv.cross_origin['stats']['total_unknown'] = lv.count_no_origin
  lv.cross_origin['stats']['offchart'] = lv.offchart.length
  lv.cross_origin['stats']['stat_string_count'] = lv.stat_string_unknown
  lv.cross_origin['stats']['stat_string_count_data'] = lv.stat_string_result

  //console.log(lv.stat_string_unknown)
  //console.log(lv.stat_string_result)

  lv.cross_origin_calculated = true

  lv.t = {}
  lv.t.unknown = numberWithDots(lv.count_no_origin)
  lv.t.uncharted = numberWithDots(lv.offchart.length)
  EventBus.$emit('calc_origin', lv.t)

}

function cross_origin_animate(){

  lv.cross_move = false
  lv.persons_ids_shuffled = JSON.parse(JSON.stringify(lv.persons_ids))
  lv.persons_ids_shuffled = util.array_shuffle(lv.persons_ids_shuffled)

  lv.nr = 0
  while(lv.nr < lv.cross_total){
    lv.mid =  lv.persons_ids[lv.nr]
    lv.vid =  lv.persons_ids_shuffled[lv.nr]
    lv.cx = 0
    lv.cy = 0
    lv.cs = 1
    if(lv.cross_origin['positions'][lv.mid]){
      lv.cx = lv.cross_origin['positions'][lv.mid].x
      lv.cy = lv.cross_origin['positions'][lv.mid].y
      lv.cs = lv.cross_origin['positions'][lv.mid].scale
    }
    lv.dly = (Math.floor(Math.random() * 100) + 1) * 0.001
    gsap.to(lv.crosses[lv.vid], {pixi: {scale: lv.cs, tint: 0xffffff, x: lv.cx, y: lv.cy }, delay: lv.dly, duration: 2.4, ease:'power4.inOut'})
    lv.nr++
  }

  console.log(lv.cross_origin['stats']['stat_string_count'])
  console.log(lv.cross_origin['stats']['stat_string_count_data'])
}

function cross_origin_show(){
  if(!lv.cross_origin_calculated){
    cross_origin_calc()
    return
  }
  cross_origin_animate()
}


var p0 = {
    scrX: 0,
    scrY: 0,
    lat: 53.54601529135908,
    lng: 3.3787755095139054
}
var p1 = {
    scrX: lv.map_width - 8,
    scrY: lv.map_height - 12,
    lat: 50.7759043990952,
    lng: 7.212696562177196
}
var radius = 6371

function latlngToGlobalXY(lat, lng){
    let x = radius*lng*Math.cos((p0.lat + p1.lat)/2);
    let y = radius*lat;
    return {x: x, y: y}
}
p0.pos = latlngToGlobalXY(p0.lat, p0.lng);
p1.pos = latlngToGlobalXY(p1.lat, p1.lng);

function latlngToScreenXY(lat, lng){
    let pos = latlngToGlobalXY(lat, lng);
    pos.perX = ((pos.x-p0.pos.x)/(p1.pos.x - p0.pos.x));
    pos.perY = ((pos.y-p0.pos.y)/(p1.pos.y - p0.pos.y));
    return {
        x: p0.scrX + (p1.scrX - p0.scrX)*pos.perX,
        y: p0.scrY + (p1.scrY - p0.scrY)*pos.perY
    }
}
//https://stackoverflow.com/a/53827343/15436154


//////////////////////////////////////// 

lv.img_offset_x = 400
lv.img_offset_y = 30

lv.firstimg = true

function cross_img(who){

  lv.cross_move = false

  lv.mtotal = lv.img2parts[who].total
  lv.mparts = lv.img2parts[who].parts
  lv.msize = lv.img2parts[who].pixel_size

  lv.delays = util.array_shuffle(lv.delays)
  lv.persons_ids = util.array_shuffle(lv.persons_ids)
  /*
  lv.name_ids_shuf = [...lv.name_ids]
  lv.name_ids_shuf = util.array_shuffle(lv.name_ids_shuf)
  */

  lv.cnt_steps_rest = 0
  lv.steps_rest = 0
  if(lv.mparts.length < lv.cross_total){
    lv.rest = lv.cross_total - lv.mparts.length
    lv.steps_rest = Math.floor(lv.mparts.length / lv.rest)
  }

  lv.nr = 0
  while(lv.nr < lv.cross_total){
    //lv.mid = lv.name_ids_shuf[lv.nr]
    lv.mid = lv.persons_ids[lv.nr]

    if(lv.nr < lv.mtotal){
      lv.pos_x = lv.mparts[lv.nr].x
      lv.pos_y = lv.mparts[lv.nr].y
      lv.scl = lv.msize
      lv.tnt = 0xffffff
      lv.delay = lv.delays[lv.nr]
    }else{
      lv.cnt_steps_rest += lv.steps_rest
      lv.pos_x = lv.mparts[lv.cnt_steps_rest].x
      lv.pos_y = lv.mparts[lv.cnt_steps_rest].y
      //lv.pos_x = 0
      //lv.pos_y = 0
      lv.scl = lv.msize
      lv.tnt = 0xffffff
      lv.delay = lv.delays[lv.nr]
    }

    lv.imgdur = 2.4
    if(who == 6){
      if(lv.firstimg){
        lv.imgdur = 0.1
        lv.delay = 0
      }
    }
    lv.pos_x += lv.img2parts[who].offset_x
    lv.pos_y += lv.img2parts[who].offset_y

    gsap.to(lv.crosses[lv.mid], {pixi: {scale: lv.scl, tint: lv.tnt, x: lv.pos_x, y: lv.pos_y }, delay: lv.delay, duration: lv.imgdur, ease:'power4.inOut'})

    lv.nr++
  }  

  lv.firstimg = false
}

function crosses_kill(){
  lv.cnr = 0
  while(lv.cnr < lv.cross_total){
    lv.mid = lv.persons_ids[lv.cnr]
    gsap.killTweensOf(lv.crosses[lv.mid])
    lv.cnr++
  }  
}

//////////////////////////////////////// events

EventBus.$on('pause_cross', (e) => {
  if(lv.cross_move){
    lv.cross_move = false
  }else{
    lv.cross_move = true
  }
})

EventBus.$on('pause_crosses', (e) => {
  lv.cross_move = false
})

EventBus.$on('play_crosses', (e) => {

  console.log('kill crosses tweens')
  crosses_kill()
  lv.cross_move = true
  
})

EventBus.$on('crosses_from_attract_to_engage', (e) => {
  //console.log('screensaver carrousel pause')
  carrousel_pause()
  //console.log('kill crosses tweens')
  crosses_kill()
  //console.log('crosses free roam')

  explode_randomify()
  
  spd.speed_global = 90
  lv.cross_move = true
  lv.dc = gsap.delayedCall(3, speed_animate,[40, 1])
})

EventBus.$on('crosses_signal', (e) => {
  carrousel_pause()
  crosses_kill()
  explode_randomify()
  spd.speed_global = 90
  lv.cross_move = true
  lv.dc = gsap.delayedCall(1, speed_animate,[10, 2])

  switch (e) {
    case '1_to_2':
      //console.log('going from 1 to 2')
      break;
    case '2_to_3':
      lv.nr = 0
      while(lv.nr < lv.cross_total){
        lv.mid = lv.persons_ids[lv.nr]
        gsap.to(lv.crosses[lv.mid], {pixi: {scale: 0.06}, duration: 0.5, ease:'none'})
        lv.nr++
      }  
      //console.log('going from 2 to 3')
      break;
    case '3_to_2':
      //console.log('going from 3 to 2')
      break;
    case '2_to_1':
      lv.nr = 0
      while(lv.nr < lv.cross_total){
        lv.mid = lv.persons_ids[lv.nr]
        gsap.to(lv.crosses[lv.mid], {pixi: {scale: 0.06}, duration: 0.5, ease:'none'})
        lv.nr++
      }  
      //console.log('going from 2 to 1')
      break;
    case '1_to_3':
      //console.log('going from 1 to 3')
      break;
    case '3_to_1':
      //console.log('going from 2 to 1')
      break;
  }
})


EventBus.$on('cross_raster', (e) => {
  cross_raster()
})


EventBus.$on('cross_origin', (e) => {
  cross_origin_show()
  gsap.to('#mapwrapper',{duration:2, opacity:1, display:'block', ease:'power3.inOut'})
})

EventBus.$on('cross_age_years', (e) => {
  cross_age_years_show()
  gsap.to('#mapwrapper',{duration:1, opacity:0, display:'none', ease:'power3.inOut'})
})

EventBus.$on('cross_age_days', (e) => {
  cross_age_days_show()
  gsap.to('#mapwrapper',{duration:1, opacity:0, display:'none', ease:'power3.inOut'})
})

EventBus.$on('cross_deceased', (e) => {
  cross_deceased_show()
  gsap.to('#mapwrapper',{duration:1, opacity:0, display:'none', ease:'power3.inOut'})
})

EventBus.$on('cross_deceased_months', (e) => {
  cross_deceased_months_show()
  gsap.to('#mapwrapper',{duration:1, opacity:0, display:'none', ease:'power3.inOut'})
})

EventBus.$on('cross_img_start', (e) => {
  cross_img('6')
  gsap.to('#mapwrapper',{duration:1, opacity:0, display:'none', ease:'power3.inOut'})
})

EventBus.$on('cross_img_1', (e) => {
  cross_img('1')
  gsap.to('#mapwrapper',{duration:1, opacity:0, display:'none', ease:'power3.inOut'})
})
EventBus.$on('cross_img_2', (e) => {
  cross_img('2')
  gsap.to('#mapwrapper',{duration:1, opacity:0, display:'none', ease:'power3.inOut'})
})
EventBus.$on('cross_img_3', (e) => {
  cross_img('3')
  gsap.to('#mapwrapper',{duration:1, opacity:0, display:'none', ease:'power3.inOut'})
})
EventBus.$on('cross_img_4', (e) => {
  cross_img('4')
  gsap.to('#mapwrapper',{duration:1, opacity:0, display:'none', ease:'power3.inOut'})
})
EventBus.$on('cross_img_5', (e) => {
  cross_img('5')
  gsap.to('#mapwrapper',{duration:1, opacity:0, display:'none', ease:'power3.inOut'})
})

EventBus.$on('cinit', (e) => {
  carrousel_init()
})

EventBus.$on('crosses_pause', (e) => {
  carrousel_pause()
})


//////////////////////////////////////// prep image to particles

function imgParser(who, what){

	var canvas = document.getElementById("scribbler")
	var ctx = canvas.getContext("2d")
	canvas.width = what.width
	canvas.height = what.height
	ctx.drawImage(what, 0, 0)
	var data = ctx.getImageData(0, 0, what.width, what.height)
	ctx.clearRect(0,0,canvas.width, canvas.height)

  //return

  lv.img2parts[who].width = what.width
  lv.img2parts[who].height = what.height
  lv.img2parts[who].total = 0
  lv.img2parts[who].parts = []

  lv.cc = 0

	for (var y = 0, y2 = data.height; y < y2; y++) {
		for (var x = 0, x2 = data.width; x < x2; x++) {
			if (data.data[(y * 4 * data.width) + (x * 4) + 3] > 128) { // ignores transparent pixels
        lv.blowup = lv.img2parts[who].blowup
        lv.prt = {
					x : x * lv.blowup,
					y : y * lv.blowup
        }
        lv.cc++
        if(lv.cc == 2){
          lv.cc = 0
        }
        lv.img2parts[who].parts.push(lv.prt)
			}
		}
	}
  lv.img2parts[who].total = lv.img2parts[who].parts.length

  //console.log(lv.img2parts)
  //console.log('total pixels for ' + lv.img2parts[who].name+ ' = ' + lv.img2parts[who].total)

  if(lv.imgCount == lv.imgTotal){
    cross_img('6')
  }
  
  loadNextImage()
}


lv.img2parts = {
  '1': {
    'name': '10',
    'url': 'images/portraits/10.png',
    //'url': 'images/part/cloud.png',
    'pixel_size': 0.07,
    'blowup': 2,
    'offset_x': 320,
    'offset_y': 30
  },
  '2': {
    'name': '1',
    'url': 'images/portraits/1.png',
    'pixel_size': 0.07,
    'blowup': 2,
    'offset_x': 750,
    'offset_y': 10
  },
  '3': {
    'name': '8',
    'url': 'images/portraits/8.png',
    'pixel_size': 0.09,
    'blowup': 2.3,
    'offset_x': 400,
    'offset_y': 30
  },
  '4': {
    'name': '2',
    'url': 'images/portraits/2.png',
    'pixel_size': 0.08,
    'blowup': 2.2,
    'offset_x': 750,
    'offset_y': 30
  },
  '5': {
    'name': '5',
    'url': 'images/portraits/5.png',
    'pixel_size': 0.09,
    'blowup': 2.3,
    'offset_x': 540,
    'offset_y': 10
  },
  '7': {
    'name': '7',
    'url': 'images/portraits/7.png',
    'pixel_size': 0.09,
    'blowup': 2,
    'offset_x': 800,
    'offset_y': 10
  },
  /*
  '6': {
    'name': 'start',
    'url': 'images/nr.png',
    'pixel_size': 0.07,
    'blowup': 0.5,
    'offset_x': 540,
    'offset_y': 370
  },
  */
  '6': {
    'name': 'start',
    'url': 'images/part/cloud.png',
    'pixel_size': 0.09,
    'blowup': 2,
    'offset_x': 590,
    'offset_y': 300
  },
}

lv.imgCount = 0
lv.imgTotal = Object.keys(lv.img2parts).length
function loadNextImage(){
  lv.imgCount++
  if(lv.imgCount > lv.imgTotal){
    var canvas = document.getElementById("scribbler")
    canvas.remove()
    return
  }
  lv.png = new Image()
  lv.png.onload = function(){
    imgParser(lv.imgCount, lv.png)
  }
  lv.png.src = lv.img2parts[lv.imgCount]['url']
}



function loadImgsFromJson(){
    fetch('json/imgs_ parts.json')
    .then(response => response.json())
    .then(function(data){
      console.log(data)
    })
}
//loadImgsFromJson()

function export2txt(data) {
  const a = document.createElement("a")
  a.href = URL.createObjectURL(new Blob([JSON.stringify(data, null, 2)], {
    type: "text/plain"
  }))
  a.setAttribute("download", "imgs_parts.json")
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}
/*
lv.t = {}
export2txt(lv.t)
*/


//////////////////////////////////////// screendaver carrousel
function carrousel_init(){
  carrousel_explode()
}
function speed_animate(s, d){
  gsap.to(spd,{duration: d, speed_global: s, ease:'none'})
}
lv.carrousel_imgs = [1,2,3,4,5,7]
lv.carrousel_img_cur = 0
function carrousel_to_img(){
  lv.c = lv.carrousel_imgs[lv.carrousel_img_cur]
  cross_img(lv.c)
  lv.carrousel_img_cur++
  if(lv.carrousel_img_cur == lv.carrousel_imgs.length){
    lv.carrousel_img_cur = 0
  }
  gsap.delayedCall(9, carrousel_explode)
}
function explode_randomify(){

    /*
    lv.nr = 0
    while(lv.nr < lv.cross_total){
      lv.mid = lv.persons_ids[lv.nr]
      lv.sc = (Math.random() * 17) * 0.01
      gsap.to(lv.crosses[lv.mid], {pixi: {scale: lv.sc}, duration: 0.5, delay: 0.5, ease:'none'})
      lv.nr++
    }  
    */

    lv.persons_ids = util.array_shuffle(lv.persons_ids)

    lv.nr = 10000
    while(lv.nr < 12000){
      lv.mid = lv.persons_ids[lv.nr]
      lv.sc = (Math.random() * 400) * 0.0013
      gsap.to(lv.crosses[lv.mid], {pixi: {scale: lv.sc}, duration: 3, delay: 0.5, ease:'none'})
      lv.nr++
    }  

    lv.nr = 12000
    while(lv.nr < 18000){
      lv.mid = lv.persons_ids[lv.nr]
      lv.sc = (Math.random() * 200) * 0.0013
      gsap.to(lv.crosses[lv.mid], {pixi: {scale: lv.sc}, duration: 3, delay: 0.5, ease:'none'})
      lv.nr++
    }  

    /*
    lv.nr = 20000
    while(lv.nr < 25000){
      lv.mid = lv.persons_ids[lv.nr]
      lv.sc = (Math.random() * 100) * 0.001
      gsap.to(lv.crosses[lv.mid], {pixi: {scale: lv.sc}, duration: 3, delay: 0.5, ease:'none'})
      lv.nr++
    }  
    */
}
function carrousel_explode(){

  explode_randomify()

  spd.speed_global = 50
  speed_animate(spd.speed_global_default, 1)
  lv.cross_move = true
  //spd.speed_global = spd.speed_global_default
  gsap.delayedCall(2, speed_animate,[10, 4])
  gsap.delayedCall(6, speed_animate,[0, 4])
  gsap.delayedCall(9, carrousel_to_img)
}
function carrousel_pause(){
  lv.cross_move = false
  gsap.killTweensOf(spd)
  gsap.killTweensOf(carrousel_to_img)
  gsap.killTweensOf(carrousel_explode)
  gsap.killTweensOf(speed_animate)
  crosses_kill()
}
//////////////////////////////////////////////////////////////

/*
cheet('p', function () {
  EventBus.$emit('play_crosses')
})

cheet('c i', function () {
  carrousel_init()
})
*/


</script>

<style scoped>


#testline{
  position: absolute;
  top:100px;
  left:50px;
  border-left:1px solid #fff;
  height:880px;
  width:1px;
}

.wrapmap{
  position: absolute;
  top:31px;
  left:130px;
}
#mapwrapper{
  display: none;
  opacity: 0;
}


</style>

