<template>
  <div>
	<div class="text-white flex wrap_pagi" id="wrap_pagi">
		<div class="flex items-center justify-center mr-2 btn_pagi pointer-events-auto" id="btn_pagi_prev" @click="prev">
			<svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path></svg>
		</div>
		<div class="p-2 px-4">
			{{obj.page_cur}}
		</div>
		<div class="p-2">
			/
		</div>
		<div class="p-2 px-4">
			{{obj.pages_total}}
		</div>
		<div class="flex items-center justify-center ml-2 btn_pagi pointer-events-auto" id="btn_pagi_next" @click="next">
			<svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
		</div>
	</div>
  </div>
</template>

<script>
import gsap from 'gsap'
import globals from '@/modules/globals'
import { EventBus } from '@/modules/event_bus'

var lv = {}

export default {
  name: "pagination_simple",
  props: ['obj'],
  components: {
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods:{
	prev(){
		if(this.obj.page_cur > 1){
			EventBus.$emit('pagination_persons_prev')
		}
	},
	next(){
		if(this.obj.page_cur < this.obj.pages_total){
			EventBus.$emit('pagination_persons_next')
		}
	}
  },
  watch:{
	obj: {
		deep: true,
		handler: function(a){
			if(a.page_cur == 1){
				gsap.set('#btn_pagi_prev',{opacity:0.3})
			}else{
				gsap.set('#btn_pagi_prev',{opacity:1})
			}
			if(a.page_cur == a.pages_total){
				gsap.set('#btn_pagi_next',{opacity:0.3})
			}else{
				gsap.set('#btn_pagi_next',{opacity:1})
			}
			if(a.pages_total < 2){
				gsap.set('#wrap_pagi',{display:'none'})
			}else{
				gsap.set('#wrap_pagi',{display:'flex'})
			}
		}
	}
  }
}
</script>

<style>

/*
.wrap_pagi{
	background: #422604;
}
*/


</style>
