<template>
  <div class="btns_language_wrap">

    <div class="flex">

      <div class="flex justify-center btn_lang" :class="{'active':(globals.language === 'nl')}" id="btn_lang_nl" @click="lang_set('nl')">
        NL
      </div>
      <div class="flex justify-center btn_lang" :class="{'active':(globals.language === 'de')}" id="btn_lang_de" @click="lang_set('de')">
        DE
      </div>
      <div class="flex justify-center btn_lang" :class="{'active':(globals.language === 'en')}" id="btn_lang_en" @click="lang_set('en')">
        EN
      </div>

    </div>
    
  </div>
</template>

<script>
import globals from '@/modules/globals'
import { EventBus } from '@/modules/event_bus'

export default {
  name: "btns_language",
  props: [],
  data() {
    return {
      globals: globals
    }
  },
  mounted() {
  },
  methods:{
    lang_set(who){
      globals.language = who
    }
  }
}
</script>

<style>

.btn_lang{
  width: 41px;
  height: 32px;
  border: 1px solid #fff;
  color: #fff;
  margin-right: 6px;
  font-size: 18px;
  line-height: 1.7;
  font-weight: bold;
  cursor: pointer;
}
.btn_lang.active{
  background: #fff;
  color: #000;
}
.btns_language_wrap{
  position: absolute;
  bottom: 0px;
  left:0px;
}

</style>

