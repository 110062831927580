<template>

  <div class="flex justify-center relative" id="graph_carr_paginate">
		<div class="flex items-center justify-center mr-4 btn_pagi pointer-events-auto bg-kettle" id="btn_carr_prev" @click="prev">
			<svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path></svg>
		</div>
    <div class="flex items-center relative carr_dots">
      <div class="carr_dot" id="carr_dot_1"></div>
      <div class="carr_dot" id="carr_dot_2"></div>
      <div class="carr_dot" id="carr_dot_3"></div>
    </div>
		<div class="flex items-center justify-center ml-4 btn_pagi pointer-events-auto bg-kettle" id="btn_carr_next" @click="next">
			<svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
		</div>
    <div class="pos-g hidden" id="carr_click_disable"></div>
  </div>

</template>

<script>
import gsap from 'gsap'
import globals from '@/modules/globals'
import { EventBus } from '@/modules/event_bus'

export default {
  name: "carrousel_graph_paginate",
  components: {
  },
  props: ['obj'],
  data() {
    return {
      cur: 1,
      total: 3
    }
  },
  mounted() {
    this.initial_state()
    EventBus.$on("attract_mode_active", (e) => {
      this.initial_state()
    })
    EventBus.$on("graph_carrousel_pagination_enable", (e) => {
      console.log('graph_carrousel_pagination_enable')
      //this.initial_state()
      //this.click_disable()
      gsap.to('#graph_carr_paginate', { duration:0.5, display: 'flex', opacity:1, ease:'power2.inOut'})
      this.click_enable()
    })
  },
  methods:{
    initial_state(){
      gsap.set('#graph_carr_paginate', { display: 'none', opacity:0})
      this.cur = 1
      gsap.set('.carr_dot', { className: 'carr_dot'})
      gsap.set('#carr_dot_'+ this.cur, { className: 'carr_dot active'})
      //gsap.set('#btn_carr_prev',{opacity:0.3})
    },
    prev(){
      /*
      if(this.cur < 2){
        return
      }
      */
      switch (this.cur) {
        case 1:
          //console.log('graph_carrousel_from_1_to_3')
          EventBus.$emit('graph_carrousel_from_1_to_3')
          break;
        case 2:
          EventBus.$emit('graph_carrousel_from_2_to_1')
          break;
        case 3:
          EventBus.$emit('graph_carrousel_from_3_to_2')
          break;
      }
      this.click_disable()
      gsap.delayedCall(0.5,this.cur_prev)
    },
    next(){
      /*
      if(this.cur > 2){
        return
      }
      */
      switch (this.cur) {
        case 1:
          EventBus.$emit('graph_carrousel_from_1_to_2')
          break;
        case 2:
          EventBus.$emit('graph_carrousel_from_2_to_3')
          break;
        case 3:
          //console.log('graph_carrousel_from_3_to_1')
          EventBus.$emit('graph_carrousel_from_3_to_1')
          break;
      }
      this.click_disable()
      gsap.delayedCall(0.5,this.cur_next)
    },
    cur_prev(){
      if(this.cur == 1){
        this.cur = 3
        return
      }
      this.cur--
    },
    cur_next(){
      if(this.cur == 3){
        this.cur = 1
        return
      }
      this.cur++
    },
    click_disable(){
      gsap.to('#graph_carr_paginate', { duration:0.5, opacity:0, ease:'power2.inOut'})
      gsap.set('#carr_click_disable',{display:'block'})
      //gsap.delayedCall(2, this.click_enable)
    },
    click_enable(){
      gsap.to('#graph_carr_paginate', { duration:0.5, opacity:1, ease:'power2.inOut'})
      gsap.set('#carr_click_disable',{display:'none'})
    }
  },
  watch: {
    cur: {
      deep: true,
      handler: function(a){
        /*
        gsap.set('#btn_carr_prev',{opacity:1})
        gsap.set('#btn_carr_next',{opacity:1})
        if(a < 2){
          gsap.set('#btn_carr_prev',{opacity:0.3})
        }
        if(a > 2){
          gsap.set('#btn_carr_next',{opacity:0.3})
        }
        */

        gsap.set('.carr_dot', { className: "carr_dot"})
        gsap.set('#carr_dot_'+ a, { className: "carr_dot active"})

      }
    }
  },
}
</script>

<style>
.carr_dot{
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border:1px solid #fff;
  margin-left: 5px;
  margin-right: 5px;
}
.carr_dot.active{
  background: #fff;
}
.carr_dots{
  top:3px;
}
</style>

