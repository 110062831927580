<template>
  <div class="relative h-full">
    <div class="pos-g" id="slat_3_bg_2"></div>

    <div class="pos-g p-46-37">

      <div class="read_main_wrap">
        <div
          v-show="!articleSelected"
          id="article_intro"
          class="pos-center mt-20 text-white hdr1"
        >
          <translations :obj="article_intro" />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="18"
            fill="white"
            class="inline-block"
            viewBox="0 0 24 24"
          >
            <path d="M21 12l-18 12v-24z" />
          </svg>
        </div>
        <div v-show="articleSelected" class="read_content_wrap hidden opacity-0" id="read_content">
          <div class="font-mono article_title" v-if="article_cur.title">
            <translations :obj="article_cur.title" />
          </div>
          <div class="flex">
            <div class="article_text mr-10" v-if="article_cur.text">
              <div class="article_text_wrap">
                <translations_html :obj="article_cur.text" />
              </div>
            </div>
            <div class="article_gallery text-white">
              <swiper v-if="articleSelected" class="swiper article-swiper" :options="swiperOptions" @ready="handleSwiperReadied">
                <swiper-slide class="swiper-slide mb-10" v-for="(item, index) in article_cur.carrousel" :key="index">

                  <div class="mb-4 text-center" v-if="item.file.mime == 'image/jpeg'">
                    <a data-fancybox="gallery_read" class="inline-block" :href="assets_path+item.file.file" :data-caption="item.caption[globals.language]" >
                      <img :src="assets_path+item.file.file+'?key=md'" style="max-height: 520px;" />
                    </a>                  
                  </div>

                  <div class="mb-4 carrousel_item relative" v-if="item.file.mime == 'video/mp4'">
                    <a data-fancybox="gallery_read" class="block w-full relative" :href="assets_path+item.file.file" :data-caption="item.caption[globals.language]" >
                      <video class="w-full object-cover">
                        <source :src="assets_path+item.file.file" type="video/mp4">
                      </video>    
                      <img src="@/assets/img/ui/play.png" class="pos-center opacity-50 videoplaybtn" />         
                    </a>
                  </div>

                  <div class="text-xs italic mb-4">
                    {{ item.caption[globals.language] }}
                  </div>
                </swiper-slide>
                <div class="swiper-pagination absolute bottom-0" slot="pagination" v-if="article_cur.carrousel" v-show="article_cur.carrousel.length > 1"></div>
              </swiper>
              <div  style="position: relative; top: -38px; z-index: 10;" v-if="article_cur.carrousel">
                <div class="flex justify-center" v-show="article_cur.carrousel.length > 1">
                  <div class="flex items-center justify-center btn_pagi_read cursor-pointer" @click="prevSlide">
                    <svg class="w-4 h-4" fill="none" stroke="#ffffff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path></svg>
                  </div>
                  <div class="ml-auto"></div>
                  <div class="flex items-center justify-center btn_pagi_read cursor-pointer" @click="nextSlide">
                    <svg class="w-4 h-4" fill="none" stroke="#ffffff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="read_intro" class="hidden">
          <div class="centerx flex">
            <div class="font-mono article_intro">
              <translations :obj="article_intro" />
            </div>
            <div class="ml-6 mt-6">
              <img src="@/assets/img/ui/arrow-right.png" />
            </div>
            <div class="pos-g"></div>
          </div>
        </div>
      </div>

      <div class="read_nav_wrap">
        <div class="read_nav_line"></div>
        <div class="read_nav_hold overflow-auto" :class="{ subject_active: this.selectedSubject !== '' }" @scroll="handleArticleScroll" id="read_scroll_wrap">
          <div id="read_scroll_content" >
            <div class="pb-6" v-for="(item, index) in filterdArticles" :key="index">
              <div class="read_nav_title">
                <translations :obj="item.title" />
              </div>
              <div class="read_nav_page_title font-sans relative flex" v-for="(page, index2) in item.articles" :key="index2" >
                <span class="mr-6">&#8226;</span>
                <translations :obj="page.title" class="read_nav_text" />
                <div class="pos-g cursor-pointer" @click="click_page(page.id)" ></div>
              </div>
            </div>
          </div>
          <div v-show="this.selectedSubject !== ''">
            <div class="bg-white text-brick flex items-center py-4 px-4 my-6">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                fill="#690A0A"
                height="16"
                viewBox="0 0 24 24"
                class="mr-6"
              >
                <path d="M24 22h-24l12-20z" />
              </svg>
              <div class="white_article_box text-xs mr-16">
                <translations :obj="article_white_box" class="" />
              </div>
              <div class="border-b border-brick">
                #<translations :obj="selectedSubjectName" />
              </div>
            </div>
            <div class="text-center" @click="resetArticleFilter">
              <span class="text-white rounded-full border border-white py-1 px-4" >
                <translations :obj="article_button_text" class="" />
              </span>
            </div>
          </div>
          <div v-if="isScrollable" class="article-scrollbar-wrapper" id="read_scroller">
            <div class="article-progressbar">
              <div class="article-progress-button bg-white rounded-full"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class=" absolute bottom-0 left-0 p-46-37">
      <div class="relative">
        <btns_language />
      </div>
    </div>
  </div>
</template>

<script>
  import gsap from "gsap";
  import globals from "@/modules/globals";
  import * as cheet from 'cheet.js'
  import { EventBus } from "@/modules/event_bus";
  import { Swiper, SwiperSlide } from "vue-awesome-swiper";
  import "swiper/css/swiper.css";
  import { Fancybox } from "@fancyapps/ui";
  import "@fancyapps/ui/dist/fancybox.css";

  import btns_language from "@/components/ui/btns_language";

  import translations from "@/components/translations";
  import translations_html from "@/components/translations_html";

  var lv = {};
  lv.thisVue = null;
  lv.selected_article = 0

  export default {
    name: "slat_read",
    components: {
      btns_language,
      Swiper,
      SwiperSlide,
      translations,
      translations_html
    },
    data() {
      return {
        globals: globals,
        articleSelected: false,
        selectedSubject: '',
        selectedSubjectName: '',
        swiperOptions: {
          spaceBetween: 5,
          effect: "slide",
          speed: 700,
          loop: true,
          pagination: {
            el: ".swiper-pagination",
          },
        },
        test_carousel: [],
        article_intro: {},
        article_white_box: {},
        article_button_text: {},
        read_nav: globals.article_chapters,
        article_cur: {},
        assets_path: globals.assets_path,
      };
    },
    mounted() {
      lv.thisVue = this
      lv.fields_translate = ['article_intro','article_white_box','article_button_text']
      for (const val of lv.fields_translate) {
        this[val] = {}
        this[val]['en'] = globals.microtranslations[val]['text_en']
        this[val]['nl'] = globals.microtranslations[val]['text_nl']
        this[val]['de'] = globals.microtranslations[val]['text_de']
      }
      EventBus.$on("attract_mode_active", (e) => {
        Fancybox.close()
        
        this.selectedSubject = ''
        this.articleSelected = false
        lv.selected_article = 0
        lv.targ = "#article_intro";
        gsap.set(lv.targ, {opacity: 1, display: "block"})
      })

      EventBus.$on("navigate_slat", (e) => {
        Fancybox.close()
      })

      
    },
    computed: {
      filterdArticles: function() {
        if (this.selectedSubject === "") {
          return this.read_nav
        }
        let articles = [];
        Object.keys(this.read_nav).forEach((key) =>{
          lv['chapter_' + key] = null
        })
        Object.keys(this.read_nav).forEach((key) =>
          Object.keys(this.read_nav[key].articles).forEach((pageKey) => {
            if (this.read_nav[key].articles[pageKey].tags.includes(this.selectedSubject)) {
              if(!lv['chapter_' + key]){
                lv['chapter_' + key] = {}
                lv['chapter_' + key]['title'] = this.read_nav[key]['title']
                lv['chapter_' + key].articles = []
              }
              lv['chapter_' + key].articles.push(this.read_nav[key].articles[pageKey]);
            }
          })
        )
        Object.keys(this.read_nav).forEach((key) => {
          if(lv['chapter_' + key]){
            articles.push(lv['chapter_' + key])
          }
        })
        return articles;
      },
      isScrollable: function() {
        const scrollBar = document.querySelector(".read_nav_hold");
        if (scrollBar && scrollBar.clientHeight >= scrollBar.scrollHeight) {
          return false;
        } else {
          return true;
        }
      },
    },
    watch:{
      selectedSubject: {
        deep: true,
        handler: function(a){
          if(this.selectedSubject != ''){
            lv.tagarray = globals.fields_data.tags
            lv.tagObj = lv.tagarray.find(o => o.id == this.selectedSubject)
            this.selectedSubjectName = lv.tagObj
            lv.selected_article = 0
            this.articleSelected = false
            lv.targ = "#article_intro";
            gsap.set(lv.targ, {opacity: 1, display: "block"})
          }
        }
      },
      filterdArticles: {
        deep: true,
        handler: function(a){
          gsap.delayedCall(0.1, ()=>{            
            this.need_scroll()
          })
        }
      },
    },
    methods: {
      fancybox_go(){
        Fancybox.bind('[data-fancybox="gallery_read"]', {
          on: {
            done: () => {
              //this.gallery_open = true
              //console.log('gallery_read_opened')
              const vids = document.querySelectorAll('.fancybox__html5video')
              vids.forEach(element => {
                console.log('wepz')
                element.setAttribute('disablePictureInPicture', false)
                element.setAttribute('controlslist', 'nodownload noplaybackrate')
              })
            },
            destroy: () => {
              //console.log('gallery_read_closed')
              //this.gallery_open = false
            },
          }
        })
      },
      handleArticleScroll(e) {
        if (!this.isScrollable) {
          return;
        }
        const scrollBar = document.querySelector(".article-progressbar");
        const totallScroll = e.target.scrollHeight - e.target.clientHeight;
        const currentScroll = e.target.scrollTop;
        scrollBar.style.height = `${(currentScroll * 100) / totallScroll +
          "%"}`;
      },
      handleSwiperReadied(swiper) {
        gsap.to('.article_gallery',{duration: 0.3, opacity:1, ease:'power4.inOut'})
      },
      nextSlide() {
        const swiper = document.querySelector(".article-swiper").swiper;
        swiper.slideNext();
      },
      prevSlide() {
        const swiper = document.querySelector(".article-swiper").swiper;
        swiper.slidePrev();
      },
      click_page(i) {
        if(lv.selected_article == i){
          return
        }
        console.log('article ' + i)
        if (!this.articleSelected) {
          this.populate_article(i)
          lv.targ = "#article_intro";
          gsap.to(lv.targ, {duration: 0.5, opacity: 0, display: "none", ease: "power4.inOut", onComplete: () => {
            this.articleSelected = true;
          }})
          lv.targ = "#read_content";
          gsap.to(lv.targ, {delay:0.5, duration: 0.5, opacity: 1, display: "block", ease: "power4.inOut"})
        } else {
          //console.log("show other article");
          lv.targ = "#read_content";
          gsap.to(lv.targ, {duration: 0.5, opacity: 0, display: "none", ease: "power4.inOut", onComplete: () => {
            this.populate_article(i)
          }})
          gsap.to(lv.targ, {delay:0.5, duration: 0.5, opacity: 1, display: "block", ease: "power4.inOut"})
        }
        lv.selected_article = i
      },
      resetArticleFilter() {
        this.selectedSubject = "";
      },
      need_scroll(){
        lv.h_wrap = document.getElementById('read_scroll_wrap').clientHeight
        lv.h_content = document.getElementById('read_scroll_content').clientHeight
        if(lv.h_content > lv.h_wrap){
          //console.log('need scroll')
          gsap.set('#read_scroller',{display: 'block'})
        }else{
          //console.log('dont need scroll')
          gsap.set('#read_scroller',{display: 'none'})
          document.querySelector(".article-progressbar").style.height = '0%'      
        }
      },
      populate_article(i){
        lv.aobj = {}
        Object.keys(globals.article_chapters).forEach((key) =>
          Object.keys(globals.article_chapters[key].articles).forEach((pageKey) => {
            if (globals.article_chapters[key].articles[pageKey].id == i) {
              lv.aobj = globals.article_chapters[key].articles[pageKey]
            }
          })
        )       
        //console.log(lv.aobj)

        this.fancybox_go()

        this.article_cur = lv.aobj
        gsap.delayedCall(1, function(){
          window.dispatchEvent(new Event('resize')) // swiper pagination bug
        })
      }
    },
  }

  cheet('s e l e c t', function () {
    lv.thisVue.selectedSubject = '2'
  })  

  cheet('a r t i c l e', function () {
    //this.articleSelected = '2'
    lv.thisVue.click_page(1)
  })  

  EventBus.$on('article_from_hotspot', (e) => {
    lv.thisVue.click_page(e)
  })


  EventBus.$on('navigate_tag', (e) => {
    lv.thisVue.selectedSubject = e
    EventBus.$emit('navigate_slat', 3)
    console.log('will catch your nav to ' + e)
  })

  EventBus.$on('navigate_slat', (e) => {
    if(e == 3){
      gsap.delayedCall(0.3, ()=>{            
        lv.thisVue.need_scroll()
      })
    }
  })

</script>

<style>
  .read_nav_wrap {
    position: absolute;
    height: 100%;
    width: 467px;
    right: 0px;
    top: 0px;
  }
  .read_nav_line {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0px;
    left: 0px;
    border-left: 1px solid #fff;
  }
  .read_nav_hold {
    padding-top: 90px;
    padding-left: 44px;
    width: 422px;
    height: 1040px;
  }

  .read_nav_page_title {
    color: #fff;
    line-height: 1.2;
  }

  .read_main_wrap {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 1220px;
    height: 100%;
  }

  #read_intro {
    position: absolute;
    width: 100%;
    top: 605px;
  }
  .article_intro {
    font-weight: bold;
    font-size: 36px;
    font-style: italic;
    color: #fff;
  }

  .article_title {
    font-size: 41px;
    font-style: italic;
    color: #fff;
    width: 547px;
    margin-bottom: 31px;
  }
  .article_text {
    width: 516px;
    height: 451px;
    font-size: 17px;
    color: #fff;
    line-height: 1.2;
  }
  .article_gallery {
    width: 500px;
    height: 451px;
    opacity: 0;
  }
  .article_img_wrap {
    width: 480px;
    height: 323px;
    background-image: url("../assets/img/temp/dummy1.jpg");
  }
  .read_nav_hold::-webkit-scrollbar {
    display: none;
  }
  .article-scrollbar-wrapper {
    width: 3px;
    height: 90%;
    right: 20px;
    position: absolute;
    top: 30px;
    background: #755e44;
  }
  .article-progressbar {
    position: relative;
    width: 3px;
    background: white;
  }
  .article-progress-button {
    position: absolute;
    bottom: -20px;
    left: -9px;
    width: 21px;
    height: 21px;
  }
  .read_nav_title {
    font: italic normal bold 11px/15px "ff-tisa-web-pro";
    font-size: 1rem;
    color: #fff;
    margin-bottom: 7px;
  }
  .read_nav_text {
    /*
    font: italic normal normal 11px/15px Calibri;
    */
    font-size: 17px;
    font-style: italic;
  }
  .subject_active {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 10px;
  }
  .white_article_box {
    max-width: 110px;
  }
  /*
  .carrousel_item{
    width:480px;
    height: 322px;;
  }
  .carousel-image {
    height: 300px;
  }
  */
  .carrousel_item{
    height: 532px;
  }
  .carousel-image {
    max-height: 532px;
  }
  .article_text_wrap{
    max-height: 630px;
    overflow: auto;
    padding-right: 20px;
  }
  .read_content_wrap {
    position: absolute;
    /*
    top: 237px;
    left: 133px;
    */
    top: 164px;
    left: 111px;
    width: 1040px;
    height: 590px;
  }

</style>
