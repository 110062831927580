<template>
  <div class="relative h-full">
    <div class="pos-g" id="slat_2_bg_2"></div>

    <div class="pos-g p-46-37">
      <div class="relative h-full">
        <div class="pos-g" id="wrap_finder">
          <div id="finder_sidebar">
            <div style="height: 134px;">
              <div class="text_instr">
                <translations :obj="filters_instruction_text" />
              </div>
              <!--  
              <div class="text_instr_bio">
                <div class="bio_indicator bg-teel rounded-full inline-block" style="margin-right: 3px;"></div>
                <translations :obj="filters_instruction_text_bio" />
              </div>
              -->
            </div>
            <p class="mb-2 text-white">
              <translations :obj="filter_results" /> ({{filter_results_nr}})
            </p>

            <div class="relative" v-click-outside="hideDropDown" :key="componentKey" >
              <input
                class="filter-input bg-yuzu focus:outline-none w-full" :class="[selectedFilters.name == '' ? '' : 'input-selected']"
                type="text"
                :placeholder="name_placeholder[globals.language]"
                v-model="selectedFilters.name"
                @click="openKeyboard"
              />
              <div v-for="item in search_fields_data" :key="item.id" class="relative">
                <div class="filter-input bg-yuzu flex items-center cursor-pointer whitespace-no-wrap" @click="handleDropdownClick($event, item, item)" v-if="!item.items">
                  <span class="field-text">
                    {{item.title[globals.language]}}
                  </span>
                  <span class="field-text-selected">
                    <translations :obj="item.selected" />
                  </span>
                  <span class="input-line"></span>
                  <div class="input-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      fill="#ffffff67"
                      class="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
                      />
                    </svg>
                  </div>
                </div>
                <div v-else class="input-multiple whitespace-no-wrap">
                  <div class="filter-input flex items-center bg-yuzu">
                    {{item.title[globals.language]}}
                  </div>
                  <div
                    class="filter-input flex items-center bg-yuzu justify-between cursor-pointer"
                    :class="{ selected: item.isSelected }"
                    v-for="child in item.items"
                    :key="child.id"
                    @click="handleDropdownClick($event, child, item)"
                  >
                    <span class="field-text">
                      {{child.title[globals.language] || child.title}}
                    </span>
                    <span class="field-text-selected">
                      <translations :obj="child.selected" />
                    </span>
                    <div class="relative input-arrow-multiple">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="#ffffff67"
                        class="bi bi-caret-down-fill"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
                        />
                      </svg>
                    </div>
                  </div>
                </div>             
              </div>

              <div class="input-multiple whitespace-no-wrap relative" style="margin-top:15px;">
                <div class="filter-input flex items-center bg-yuzu" style="width:20px; height:20px;">
                  <div class="relative" style="left:-7px;" v-show="show_bios">
                    <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
                  </div>
                </div>
                <div class="text_instr_bio pl-2" style="position:relative; top:-2px;">
                  <translations :obj="filters_instruction_text_bio" />
                  <div class="bio_indicator bg-teel rounded-full inline-block" style="margin-left:3px; position:relative; top:2px;"></div>
                </div>
                <div class="pos-g cursor-pointer" @click="toggle_showbios"></div>
              </div>

              <div class="keyboard bg-roast text-white ml-px5 p-1 z-10 box-content" v-if="isKeyboardOpen" >
                <div
                  class="keyboard-btn hide-keyboard rounded-full border-white border flex items-center justify-center"
                  @click="hideKeyboard"
                >
                  <svg
                    style="width:100%"
                    stroke="#fff"
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="cross">
                      <line class="cls-1" x1="7" x2="25" y1="7" y2="25" />
                      <line class="cls-1" x1="7" x2="25" y1="25" y2="7" />
                    </g>
                  </svg>
                </div>
                <div class="mb-10">
                  <div class="flex justify-center mb-1 last:mr-0">
                    <div
                      class="keyboard-btn rounded-full border-white border flex items-center justify-center mr-2"
                      @click="keyBtnClick(btn)"
                      v-for="btn in keyboard_rows[0]"
                      :key="btn"
                    >
                      {{ btn }}
                    </div>
                  </div>
                  <div class="flex justify-center mb-1">
                    <div
                      class="keyboard-btn rounded-full border-white border flex items-center justify-center mr-2"
                      @click="keyBtnClick(btn)"
                      v-for="btn in keyboard_rows[1]"
                      :key="btn"
                    >
                      {{ btn }}
                    </div>
                  </div>
                  <div class="flex justify-center mb-2">
                    <div
                      class="keyboard-btn rounded-full border-white border flex items-center justify-center mr-2"
                      v-for="btn in keyboard_rows[2]"
                      @click="keyBtnClick(btn)"
                      :key="btn"
                    >
                      <div v-if="btn !== 'delete'">
                        {{ btn }}
                      </div>
                      <div v-else class="delete-wrapper">
                        <delete_btn />
                      </div>
                    </div>
                  </div>
                  <div class="flex justify-center">
                    <div
                      class="space-button rounded-full border-white border text-center cursor-pointer"
                      @click="keyBtnClick(' ')"
                    >
                      space
                    </div>
                  </div>
                </div>
              </div>

              <!--  
                second keyboard...
              -->
              <div class="keyboard keyboard_sub bg-roast text-white ml-px5 p-1 z-10 box-content" v-if="isKeyboardSubOpen" >
                <div class="keyboard_sub_bridge"></div>
                <div class="keyboard-btn hide-keyboard rounded-full border-white border flex items-center justify-center" @mousedown="hideKeyboardSub" >
                  <svg style="width:100%" stroke="#fff" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" >
                    <g id="cross">
                      <line class="cls-1" x1="7" x2="25" y1="7" y2="25" />
                      <line class="cls-1" x1="7" x2="25" y1="25" y2="7" />
                    </g>
                  </svg>
                </div>
                <div class="mb-10">
                  <div class="flex justify-center mb-1 last:mr-0">
                    <div class="keyboard-btn rounded-full border-white border flex items-center justify-center mr-2" @click="keyBtnSubClick(btn)"
                      v-for="btn in keyboard_rows[0]" :key="btn"
                    >
                      {{ btn }}
                    </div>
                  </div>
                  <div class="flex justify-center mb-1">
                    <div class="keyboard-btn rounded-full border-white border flex items-center justify-center mr-2" @click="keyBtnSubClick(btn)"
                      v-for="btn in keyboard_rows[1]" :key="btn"
                    >
                      {{ btn }}
                    </div>
                  </div>
                  <div class="flex justify-center mb-2">
                    <div class="keyboard-btn rounded-full border-white border flex items-center justify-center mr-2"  @click="keyBtnSubClick(btn)"
                      v-for="btn in keyboard_rows[2]" :key="btn"
                    >
                      <div v-if="btn !== 'delete'">
                        {{ btn }}
                      </div>
                      <div v-else class="delete-wrapper">
                        <delete_btn />
                      </div>
                    </div>
                  </div>
                  <div class="flex justify-center">
                    <div  class="space-button rounded-full border-white border text-center cursor-pointer" @click="keyBtnSubClick(' ')" >
                      space
                    </div>
                  </div>
                </div>
              </div>


              <div class="drop-down-options bg-roast text-white ml-px5 px-px10 py-px10 z-10" v-if="isDropDownOpen" >
                <div class="filter-close-btn flex items-center justify-center" @click="removeFilter" >
                  <delete_btn style="width:100%;" />
                </div>

                <div class="text-center invisible">
                  {{ dropDownHeader }}
                </div>

                <div class="my-2" style="width:253px;" v-show="needFilterSub">
                  <input class="filter-input bg-yuzu focus:outline-none w-full filter_tags" type="text" :placeholder="dropDownHeader" v-model="filterSub" @click="openKeyboardSub" />
                </div>

                <div class="drop-down-items-wrapper" :class="[needFilterSub ? 'dropdown_max_small' : 'dropdown_max_big']" @scroll="handleDropDownScrollStatus" >
                  <div class=" border-b border-siskin py-px10 cursor-pointer" @click="handleDropDownSelect(item)"
                    v-for="item in filtered_options" :key="item.id"
                  >
                    {{ item[globals.language] || item }}
                  </div>
                </div>
                <div class="mt-4">
                  <div v-if="filtered_options.length > 10" class="">
                    <svg
                      @mousedown="dropDownScrollDown"
                      @mouseup="stopDropDownScroll"
                      v-if="!isDropDownScrolledDown"
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      fill="#ffffff67"
                      class="bi bi-caret-down-fill mx-auto"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
                      />
                    </svg>
                    <svg
                      @mousedown="dropDownScrollUp"
                      @mouseup="stopDropDownScroll"
                      v-else
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      fill="#ffffff67"
                      class="bi bi-caret-up-fill mx-auto"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              <div class="flex text-white my-6 cursor-pointer" :class="[filters_clearable ? '' : 'opacity-50']" @click="resetFilters">
                <div class="mr-px10">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="#fff"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 16.094l-4.157-4.104 4.1-4.141-1.849-1.849-4.105 4.159-4.156-4.102-1.833 1.834 4.161 4.12-4.104 4.157 1.834 1.832 4.118-4.159 4.143 4.102 1.848-1.849z"
                    />
                  </svg>
                </div>
                <span>
                  <translations :obj="clear_filters" />
                </span>
              </div>
            </div>
            <div class="text-white hidden">{{ selectedFilters }}</div>
          </div>

          <div id="finder_names" class="">
            <div id="wrapper_names"
              class="names-wrapper text-white h-full overflow-hidden text-2xl"
              :class="{ 'fade-active': !isNameWrapperScrolledDown }"
              @scroll="handleNameScroll"
            >
            <!-- 

              <div class="grid_vertical h-full" id="content_names">
              <div class="grid grid-flow-col grid-cols-4 grid-rows-17 gap-4 " style="height: 890px; width: 1200px;" id="content_names">
             -->
              <div class="grid grid-cols-4 gap__09" id="content_names">
                <div
                  v-for="person in persons_page_cur"
                  :key="person.id"
                  class="person-name cursor-pointer"
                  @click="handleNameClick(person)"
                >
                  {{person.name}}
                  <div v-if="person.has_bio_of_interest" class="bio_indicator bg-teel rounded-full inline-block ml-1"></div>
                </div>
                <!--  
                <div class="h-48"></div>
                -->
              </div>
              
            </div>
            <div class="absolute bottom-0 left-0 w-full flex items-center justify-center pointer-events-none" style="bottom:-5px;">
              <pagination_simple  :obj="{ 'pages_total': pages_total, 'page_cur': page_cur }" />
            </div>
            <div class="scrollbar-wrapper hidden" id="find_scroller">
              <div class="progressbar">
                <div class="progress-button bg-white rounded-full"></div>
              </div>
            </div>
          </div>
        </div>

        <btns_language />
      </div>
    </div>
  </div>
</template>

<script>
  import gsap from "gsap";
  import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
  gsap.registerPlugin(ScrollToPlugin)
  import * as cheet from 'cheet.js'

  import globals from "@/modules/globals";
  import { EventBus } from "@/modules/event_bus";
  import ClickOutside from "vue-click-outside";

  import btns_language from "@/components/ui/btns_language";
  import delete_btn from "@/components/ui/delete_btn";
  import pagination_simple from "@/components/ui/pagination_simple";

  import translations from "@/components/translations";

  var lv = {};
  lv.thisVue = null

  lv.fields_module = ['tags','nationality','cause_of_death','military_rank','army_unit']
  lv.fields_str = ['block','birth_location','birth_date_day','birth_date_month','birth_date_year','deceased_date_day','deceased_date_month','deceased_date_year']
  lv.fields_str.push('sex','deceased_location','origin_location','transmission_date_day','transmission_date_month','transmission_date_year')
  lv.fields_nr = ['age','row','grave']

  lv.fields_filter = lv.fields_str.concat(lv.fields_nr).concat(lv.fields_module)
  //console.log(lv.fields_filter)

  lv.persons_chunked = []

  export default {
    name: "slat_find",
    components: {
      btns_language,
      translations,
      delete_btn,
      pagination_simple
    },
    data() {
      return {
        temp_name_data: null,
        persons_data: [],
        persons_page_cur: [],
        pages_total: null,
        page_cur: null,
        scrollInterval: false,
        globals: globals,
        keyboard_rows: {
          0: ["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P"],
          1: ["A", "S", "D", "F", "G", "H", "J", "K", "L"],
          2: ["Z", "X", "C", "V", "B", "N", "M", "delete"],
        },
        componentKey: 0,
        isDropDownScrolledDown: false,
        isNameWrapperScrolledDown: false,
        name_placeholder: { en: "Name", nl: "Naam", de: "Name" },
        clear_filters: {},
        filter_results: {},
        filters_instruction_text:{},
        filters_instruction_text_bio:{},
        selectedFilters: {
          name: "",
        },
        filter_results_nr: null,
        filters_clearable: false,
        isDropDownOpen: false,
        isKeyboardOpen: false,
        selectedInput: "",
        currentDropDownData: "",
        search_fields_data:[],
        temp_data: [],
        isKeyboardSubOpen: false,
        filterSub: "",
        needFilterSub: false,
        show_bios: false
      };
    },
    directives: {
      ClickOutside,
    },
    mounted() {

      lv.thisVue = this

      this.populate_search_fields()

      lv.fields_translate = ['clear_filters','filter_results','filters_instruction_text','filters_instruction_text_bio']
      for (const val of lv.fields_translate) {
        this[val] = {}
        this[val]['en'] = globals.microtranslations[val]['text_en']
        this[val]['nl'] = globals.microtranslations[val]['text_nl']
        this[val]['de'] = globals.microtranslations[val]['text_de']
      }

      this.filter_results_nr = globals.persons_data.length

      this.chunkify()

      EventBus.$on('pagination_persons_prev', (e) => {
        this.page_cur--
        this.persons_page_cur = lv.persons_chunked[this.page_cur - 1]
        gsap.to('#wrapper_names', {duration: 0.4, scrollTo: {y: 0}, ease:'power3.inOut'})
        gsap.delayedCall(0.1, ()=>{            
          this.need_scroll()
        })
      })
      EventBus.$on('pagination_persons_next', (e) => {
        this.page_cur++
        this.persons_page_cur = lv.persons_chunked[this.page_cur - 1]
        gsap.to('#wrapper_names', {duration: 0.4, scrollTo: {y: 0}, ease:'power3.inOut'})
        gsap.delayedCall(0.1, ()=>{            
          this.need_scroll()
        })
      })
      EventBus.$on("attract_mode_active", (e) => {
        this.resetter();
        gsap.delayedCall(0.1, ()=>{            
          this.need_scroll()
        })
      })
    },
    computed: {
      dropDownHeader() {
        // check if it is singular or multiple filter
        if (this.currentDropDownData.parent[globals.language] === this.currentDropDownData.title[globals.language])
          return this.currentDropDownData.parent[globals.language];
        else {
          return this.currentDropDownData.parent[globals.language];
        }
      },
      persons_filter() {
          let temp_data = []

          //console.log('.................................................... compute persons_filter')
          //console.log(this.selectedFilters)
          
          if(globals.persons_data.length > 0){
            temp_data = globals.persons_data
          }

          if (this.selectedFilters.name && this.selectedFilters.name != '') {
            temp_data = temp_data.filter((item) => {
              return item.name.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase().includes(this.selectedFilters.name.toUpperCase())
              //return item.name_view.toUpperCase().includes(this.selectedFilters.name.toUpperCase())
            })
          }

          for (const val of lv.fields_filter) {
            if (this.selectedFilters[val] && this.selectedFilters[val] != '') {
              temp_data = temp_data.filter((item) => {
                if(item[val]){
                  if(Array.isArray(item[val])){
                    return item[val].includes(this.selectedFilters[val])
                  }else{
                    if(item[val] == this.selectedFilters[val]){
                      return item[val]
                    }
                  }
                }else{
                  //console.log('unknown ' + val + ' for ' + item.id)
                }
              })
            }
          }

          if (this.selectedFilters.has_bio_of_interest && this.selectedFilters.has_bio_of_interest != '') {
            temp_data = temp_data.filter((item) => {
              if(item['has_bio_of_interest']){
                return item
              }
            })
          }
                    
          return temp_data
      },
      filtered_options(){
        let temp_sub = []

        temp_sub = this.currentDropDownData.options

        if (this.filterSub !== "") {
          temp_sub = temp_sub.filter((item) => {
            return item.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase().includes(this.filterSub.toUpperCase())
          })
        }

        return temp_sub
      }
    },
    watch: {
      persons_filter: {
        deep: true,
        handler: function(a){
          this.filter_results_nr = a.length
          this.chunkify()
          gsap.to('#wrapper_names', {duration: 0.4, scrollTo: {y: 0}, ease:'power3.inOut'})
          gsap.delayedCall(0.1, ()=>{            
            this.need_scroll()
          })
        }
      },
      selectedFilters: {
        deep: true,
        handler: function(a){
          lv.sfchk = Object.keys(a).length
          if(lv.sfchk == 1 && a.name == ''){
            this.filters_clearable = false
          }else{
            this.filters_clearable = true
          }
        }
      }
    },
    methods: {
      toggle_showbios(){
        if(this.show_bios){
          this.show_bios = false
          delete this.selectedFilters['has_bio_of_interest']
        }else{
          this.show_bios = true
          this.selectedFilters['has_bio_of_interest'] = true
        }
        this.selectedFilters = JSON.parse(JSON.stringify(this.selectedFilters))
        this.resetFilterSub()
        this.hideDropDown()
        this.hideKeyboard()
      },
      chunkify(){
        //lv.perChunk = 76 
        lv.perChunk = 68 
        lv.persons_chunked = this.persons_filter.reduce((resultArray, item, index) => { 
          const chunkIndex = Math.floor(index/lv.perChunk)
          if(!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = []
          }
          resultArray[chunkIndex].push(item)
          return resultArray
        }, [])

        this.persons_page_cur = lv.persons_chunked[0]
        this.pages_total = lv.persons_chunked.length
        this.page_cur = 1

        /*
        console.log('===== persons chunked =====')
        console.log('total filter results: ' + this.persons_filter.length)
        console.log('total pages: ' + this.pages_total)
        console.log('show per page: ' + lv.perChunk)
        console.log(this.persons_page_cur)
        */
      },
      populate_search_fields(){

        //console.log(globals.fields_data)

        for (const val of lv.fields_str) {
          if(globals.fields_data[val]){
            globals.fields_data[val].sort()
          }
        }
        for (const val of lv.fields_nr) {
          if(globals.fields_data[val]){
            globals.fields_data[val].sort(function(a, b) {
              return a - b;
            })
          }
        }

        /*
        const age_minus = globals.fields_data.age.indexOf('-1');
        if (age_minus > -1) {
          globals.fields_data.age.splice(age_minus, 1);
        }
        */

        lv.field_identifiers = ['tags','block_row_grave','block','row','grave','birth_date','deceased_date','age','sex','male','female']
        lv.field_identifiers.push('birth_location','deceased_location','nationality','cause_of_death','military_rank','army_unit','origin_location','transmission_date')

        lv.fields_names = {
          'birth_date_day': {
            nl: '00', de: '00', en: '00'
          },
          'birth_date_month': {
            nl: '00', de: '00', en: '00'
          },
          'birth_date_year': {
            nl: '0000', de: '0000', en: '0000'
          },
          'deceased_date_day': {
            nl: '00', de: '00', en: '00'
          },
          'deceased_date_month': {
            nl: '00', de: '00', en: '00'
          },
          'deceased_date_year': {
            nl: '0000', de: '0000', en: '0000'
          },
          'transmission_date_day': {
            nl: '00', de: '00', en: '00'
          },
          'transmission_date_month': {
            nl: '00', de: '00', en: '00'
          },
          'transmission_date_year': {
            nl: '0000', de: '0000', en: '0000'
          },
        }
        for (const val of lv.field_identifiers) {
          lv.fields_names[val] = {}
          if(globals.microtranslations[val]){
            lv.fields_names[val]['en'] = globals.microtranslations[val]['text_en']
            lv.fields_names[val]['nl'] = globals.microtranslations[val]['text_nl']
            lv.fields_names[val]['de'] = globals.microtranslations[val]['text_de']
          }else{
            console.log('cant find micortranslations for ' + val)
          }
        }
        
        globals.fields_names = lv.fields_names

        globals.fields_data.sex = []
        lv.sex_items = ['male','female']
        for (const val of lv.sex_items) {
          lv.o = {}
          lv.o.id = val
          lv.o.en = lv.fields_names[val].en
          lv.o.nl = lv.fields_names[val].nl
          lv.o.de = lv.fields_names[val].de
          globals.fields_data.sex.push(lv.o)
        }

        lv.fields_parse = [
          {name: 'tags', items_translations: true},
          {name: 'block_row_grave', items_translations: false, 
            items:[
              {name: 'block'}, {name: 'row'}, {name: 'grave'},
            ]
          },
          {name: 'birth_date', items_translations: false, 
            items:[
              {name: 'birth_date_day'}, {name: 'birth_date_month'}, {name: 'birth_date_year'},
            ]
          },
          {name: 'deceased_date', items_translations: false, 
            items:[
              {name: 'deceased_date_day'}, {name: 'deceased_date_month'}, {name: 'deceased_date_year'},
            ]
          },
          {name: 'age', items_translations: false},
          {name: 'sex', items_translations: false},
          {name: 'birth_location', items_translations: false},
          {name: 'origin_location', items_translations: false},
          {name: 'transmission_date', items_translations: false, 
            items:[
              {name: 'transmission_date_day'}, {name: 'transmission_date_month'}, {name: 'transmission_date_year'},
            ]
          },
          {name: 'nationality', items_translations: true},
          {name: 'cause_of_death', items_translations: true},
          {name: 'military_rank', items_translations: true},
          {name: 'army_unit', items_translations: true},
          {name: 'deceased_location', items_translations: false},
        ]

        for (const val of lv.fields_parse) {
            lv.obj = {}
            lv.field = val.name
            lv.obj.id = lv.field
            lv.obj.title = lv.fields_names[lv.field]
            lv.obj.multiple = val.multiple

            if(val.items){
              lv.obj.items = []
              for (const i of val.items) {
                lv.o = {}
                lv.o.id = i.name
                lv.o.title = lv.fields_names[i.name]
                lv.o.options = globals.fields_data[i.name]
                lv.o.selected = ''
                lv.obj.items.push(lv.o)
              }
            } else {
              lv.obj.options = globals.fields_data[lv.field]
              lv.obj.selected = ''
            }

            this.search_fields_data.push(lv.obj)
        }

        //console.log('fields data =====')
        //console.log(this.search_fields_data)

      },
      resetter(){
        this.resetFilters()
        this.page_cur = 1
        this.persons_page_cur = lv.persons_chunked[this.page_cur - 1]
        gsap.to('#wrapper_names', {duration: 0.1, scrollTo: {y: 0}, ease:'power3.inOut'})
      },
      removeFilter() {
        this.isDropDownOpen = false;
        lv.targ = this.selectedInput.getElementsByClassName("field-text")[0]
        gsap.set(lv.targ,{display:'inline-block'})
        this.currentDropDownData.selected = ''
        this.selectedInput.classList.remove("input-selected");
        delete this.selectedFilters[this.currentDropDownData.id]
        // invoke firing of persons_filter by reassigning the whole object
        this.selectedFilters = JSON.parse(JSON.stringify(this.selectedFilters))
        
      },
      keyBtnClick(btn) {
        btn !== "delete"
          ? (this.selectedFilters.name += btn)
          : (this.selectedFilters.name = this.selectedFilters.name.slice(
              0,
              -1
            ));
      },
      openKeyboard() {
        this.isDropDownOpen = false;
        this.isKeyboardOpen = true;
      },
      hideKeyboard() {
        this.isKeyboardOpen = false;
      },
      keyBtnSubClick(btn) {
        btn !== "delete" ? (this.filterSub += btn) : (this.filterSub = this.filterSub.slice( 0, -1 ))
      },
      openKeyboardSub() {
        this.isKeyboardSubOpen = true
      },
      hideKeyboardSub() {
        this.isKeyboardSubOpen = false
        console.log('hideKeyboardSub')
      },
      resetFilters() {
        this.componentKey += 1;
        this.selectedFilters = {
          name: "",
        };

        for (const val of this.search_fields_data) {
            if(val.items){
              for (const i of val.items) {
                i.selected = ''
              }
            } else {
              val.selected = ''
            }
        }
        this.show_bios = false
      },
      resetFilterSub(){
        this.filterSub = ""
        this.isKeyboardSubOpen = false
      },
      handleDropdownClick(event, item, parent) {
        // inactive all active input fields first
        const activeInput = document.querySelectorAll(".input-active");
        activeInput.length !== 0
          ? activeInput[0].classList.remove("input-active")
          : null;

        this.isKeyboardOpen = false;
        this.isDropDownOpen = true;
        item.parent = parent.title;
        this.currentDropDownData = item;

        //console.log(item);

        let needSubFilter = ['birth_location','deceased_location','origin_location']
        this.needFilterSub = false
        if(needSubFilter.includes(item.id)){
          this.needFilterSub = true
        }

        // activate selected input field
        this.selectedInput = event.currentTarget;
        //console.log(this.selectedInput);
        this.selectedInput
          .getElementsByTagName("DIV")[0]
          .classList.add("input-active");
        // reset scroll
        setTimeout(() => {
          const dropDownItems = document.querySelector(
            ".drop-down-items-wrapper"
          );
          dropDownItems.scrollTop = 0;
        }, 100);

        this.resetFilterSub()
      },
      handleDropDownSelect(item) {

        //console.log('_____currentDropDownData_____')
        //console.log(this.currentDropDownData)
        
        this.isDropDownOpen = false;
        this.selectedInput.classList.add("input-selected");

        if(item[globals.language]){
          this.currentDropDownData.selected = item;
        }else{
          this.currentDropDownData.selected = {'nl': item, 'en': item, 'de': item};
        }
        lv.targ = this.selectedInput.getElementsByClassName("field-text")[0]
        gsap.set(lv.targ,{display:'none'})

        //console.log('================ filter part')
        console.log(this.selectedFilters)

        //console.log(item)
        if(item.id){
          //console.log(this.currentDropDownData.id + ' : ' + item.id)
          this.selectedFilters[this.currentDropDownData.id] = item.id
        }else{
          //console.log(this.currentDropDownData.id + ' : ' + item)
          this.selectedFilters[this.currentDropDownData.id] = item
        }

        // invoke firing of persons_filter by reassigning the whole object
        this.selectedFilters = JSON.parse(JSON.stringify(this.selectedFilters))

        this.resetFilterSub()
      },
      hideDropDown() {
        this.isDropDownOpen = false;
        if (this.selectedInput.length !== 0) {
          this.selectedInput
            .getElementsByTagName("DIV")[0]
            .classList.remove("input-active");
        }

        this.resetFilterSub()
      },
      handleDropDownScrollStatus(e) {
        // check if scrolled all the way down
        const totallScroll = e.target.scrollHeight - e.target.clientHeight;
        const currentScroll = e.target.scrollTop;
        if (totallScroll == currentScroll) {
          this.isDropDownScrolledDown = true;
        } else {
          this.isDropDownScrolledDown = false;
        }
      },
      handleNameScroll(e) {
        const scrollBar = document.querySelector(".progressbar");
        const totallScroll = e.target.scrollHeight - e.target.clientHeight;
        const currentScroll = e.target.scrollTop;
        if (totallScroll == currentScroll) {
          this.isNameWrapperScrolledDown = true;
        } else {
          this.isNameWrapperScrolledDown = false;
        }
        scrollBar.style.height = `${(currentScroll * 100) / totallScroll +  "%"}`;
      },
      dropDownScrollDown() {
        const dropDownItems = document.querySelector(
          ".drop-down-items-wrapper"
        );
        this.scrollInterval = setInterval(() => {
          dropDownItems.scrollTop += 1;
        }, 5);
      },
      dropDownScrollUp() {
        const dropDownItems = document.querySelector(
          ".drop-down-items-wrapper"
        );
        this.scrollInterval = setInterval(() => {
          dropDownItems.scrollTop -= 1;
        }, 5);
      },
      stopDropDownScroll() {
        clearInterval(this.scrollInterval);
        this.scrollInterval = false;
      },
      handleNameClick(info) {
        this.hideKeyboard()
        EventBus.$emit("modal_search_open", info);
      },
      need_scroll(){
        lv.h_wrap = document.getElementById('wrapper_names').clientHeight
        lv.h_content = document.getElementById('content_names').clientHeight
        if(lv.h_content > lv.h_wrap){
          //console.log('need scroll for find')
          //gsap.set('#find_scroller',{display: 'block'})
        }else{
          //console.log('dont need scroll for find')
          gsap.set('#find_scroller',{display: 'none'})
          document.querySelector(".progressbar").style.height = '0%'      
        }
      },
    },

  };

  cheet('s h o w b i o s', function () {
    lv.thisVue.selectedFilters = {'name':'','has_bio_of_interest': true}
  })

</script>

<style scoped>
  .filter-input {
    position: relative;
    font-size: 14px;
    border: solid 1px #ffffff67;
    height: 38px;
    color: #fff;
    padding: 0 10px;
    margin-bottom: 5px;
  }
  .filter-input::placeholder {
    color: #fff;
  }
  .input-line {
    width: 1px;
    height: 85%;
    background: #ffffff67;
    position: absolute;
    right: 35px;
  }
  .input-arrow {
    position: absolute;
    right: 12.5px;
  }
  .input-arrow-multiple {
    position: absolute;
    right: 7px;
  }
  .input-multiple {
    display: grid;
    grid-template-columns: min-content auto auto auto;
    grid-gap: 5px;
  }
  .keyboard {
    position: absolute;
    top: 0;
    left: 100%;
    border: solid 1px #ffffff67;
    width: 500px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 737px;
  }
  .hide-keyboard {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .keyboard-btn {
    width: 34px;
    height: 34px;
    cursor: pointer;
  }
  .filter-close-btn {
    width: 25px;
    height: 25px;
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 5px;
  }
  .keyboard-btn:nth-last-child(1) {
    margin-right: 0;
  }
  .space-button {
    width: 285px;
  }
  .delete-wrapper {
    width: 22px;
    height: 22px;
  }
  .drop-down-options {
    position: absolute;
    top: 0px;
    left: 100%;
    border: solid 1px #ffffff67;
    border-top: solid 2px #ffffff67;
    width: 280px;
    height: 640px;
  }
  .drop-down-items-wrapper {
    overflow: auto;
    /*
    max-height: 592px;
    max-height: 568px;
    height: 509px;
    */
  }
  .dropdown_max_big{
    max-height: 569px;
  }
  .dropdown_max_small{
    max-height: 509px;
  }
  .input-selected {
    background: #ffffff8b;
    color: #8b5c24;
  }
  .input-active {
    transform: rotate(-90deg);
  }
  .names-wrapper {
    padding: 30px 55px 0 100px;
    gap: 20px 40px;
  }
  /*
  .names-wrapper::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 30%;
    height: 100%;
    background-image: (
      linear-gradient(to left, rgba(255, 255, 255, 0) 0%, white 80%, white 100%)
    );
    pointer-events: none;
  }
  .fade-active {
    -webkit-mask-image: linear-gradient(to bottom, black 75%, transparent 100%);
    mask-image: linear-gradient(to bottom, black 75%, transparent 100%);
  }
  */
  .names-wrapper::-webkit-scrollbar {
    display: none;
  }
  .scrollbar-wrapper {
    width: 3px;
    height: 95%;
    right: 0;
    position: absolute;
    top: 30px;
    background: #755e44;
  }
  .progressbar {
    position: relative;
    width: 3px;
    background: white;
  }
  .progress-button {
    position: absolute;
    bottom: -20px;
    left: -9px;
    width: 21px;
    height: 21px;
  }
  /* .fade-out {
    height: 15%;
    position: absolute;
    bottom: -2px;
    opacity: 0.7;
    width: 100%;
    background: linear-gradient(to right, #5d3405 0%, #432304 100%) 0% 0%
      no-repeat padding-box;
  } */



.person-name{
  font-weight: 100;
  font-size: 25px;
}

.bio_indicator{
  width:12px;
  height: 12px;
}

.grid_vertical {
  column-count: 4;
  height: 900px;
}
.grid_vertical_child{
  margin-right: 10px;
  margin-bottom:10px;
}

.text_instr{
  color: #fff;
  font-size: 17px;
  padding-top: 35px;
  line-height: 1.3;
}

.text_instr_bio{
  color: #fff;
  font-size: 16px;
  /*
  border-bottom: 1px solid #fff;
  padding: 7px 0px 11px 0px;
  */
}

.gap__09{
  grid-gap: 0.9rem;
  gap: 0.9rem;
}

.filter_tags::-webkit-input-placeholder {
  color: rgba(255,255,255, 0.5);
}

.keyboard_sub{
  left: 585px;
  height: 631px;
  width: 455px;
}
.keyboard_sub_bridge{
  position: absolute;
  top: 0px;
  left: -11px;
  width: 10px;
  height: 100%;
  background: #663905;
}


</style>
