<template>

  <div class="pos-g w-slat">

    <div class="pos-g infograph_wrap" id="igraph_1">

      <div class="igraph_1_ui_el" id="igraph_1_graph">
        <img src="@/assets/img/graph_age_year.png" />
      </div>

      <div class="text-hdr text_inf1 igraph_1_ui_el" id="igraph_1_title">
        <translations :obj="age_deceased_title" />
      </div>
      
      <div class="text-hdr text_nr_inf1 igraph_1_ui_el" id="igraph_1_nr_high">
        <div class="italic inline-block">{{nr_1_total_high_age}}</div>
        <div class="igraph_line inline-block"></div>
      </div>

      <div class="text-hdr text_inf1_2 igraph_1_ui_el" id="igraph_1_nr_unknown">
        <div class="italic">
          {{nr_1_total_unknown}}
        </div>
        <div class="text_nr_inf2">
          <translations :obj="age_unknown" />
        </div>
      </div>

      <div class="pos-g pointer-events-none" v-if="[hotspots.age.length > 0]">
        <btn_hotspot class="igraph_1_ui_el" v-for="item in hotspots.age" :key="item.id" :obj="item" />
      </div>

    </div>

    <div class="pos-g infograph_wrap" id="igraph_2">

      <div class="text-hdr text_inf1 igraph_2_ui_el" id="igraph_2_title">
        <translations :obj="origin_location_title" />
      </div>


      <div class="text-hdr text_inf1_2 igraph_2_ui_el" id="igraph_2_nr_unknown">
        <div class="italic">
          {{nr_2_total_unknown}}
        </div>
        <div class="text_nr_inf2">
          <translations :obj="origin_unknown" />
        </div>
      </div>

      <div class="text-hdr text_inf1_2 igraph_2_ui_el" id="igraph_2_offchart">
        <div class="italic">
          {{nr_2_total_offchart}}
        </div>
        <div class="text_nr_inf2">
          <translations :obj="origin_offchart" />
        </div>
      </div>

      

      <div class="pos-g pointer-events-none" v-if="[hotspots.origin.length > 0]">
        <btn_hotspot class="igraph_2_ui_el" v-for="item in hotspots.origin" :key="item.id" :obj="item" />
      </div>

    </div>

    <div class="pos-g infograph_wrap" id="igraph_3">

      <div class="igraph_3_ui_el" id="igraph_3_graph">
        <img src="@/assets/img/graph_deceased_months.png" />
      </div>

      <div class="text-hdr text_inf1 igraph_3_ui_el" id="igraph_3_title">
        <translations :obj="date_deceased_title" />
      </div>
      
      <div class="text-hdr text_nr_inf1 igraph_3_ui_el" id="igraph_3_nr_high">
        <div class="igraph_line_2 inline-block"></div>
        <div class="italic inline-block">{{nr_3_total_high}}</div>
      </div>

      <div class="text-hdr text_inf1_2 igraph_3_ui_el" id="igraph_3_nr_unknown">
        <div class="italic">
          {{nr_3_total_unknown}}
        </div>
        <div class="text_nr_inf2">
          <translations :obj="deceased_unknown" />
        </div>
      </div>


      <div class="pos-g pointer-events-none" v-if="[hotspots.deceased.length > 0]">
        <btn_hotspot class="igraph_3_ui_el" v-for="item in hotspots.deceased" :key="item.id" :obj="item" />
      </div>

    </div>

  </div>

</template>

<script>
import gsap from 'gsap'
import globals from '@/modules/globals'
import { EventBus } from '@/modules/event_bus'

import btn_hotspot from '@/components/ui/btn_hotspot'

import translations from '@/components/translations'

var lv = {}

/*
hotspots: age | origin | deceased
*/

export default {
  name: "carrousel_infograph",
  components: {
    btn_hotspot,
    translations
  },
  props: ['obj'],
  data() {
    return {
      globals: globals,
      nr_1_total_high_age: '12.000',
      nr_1_total_unknown: '19.200',
      nr_2_total_high_age: '12.000',
      nr_2_total_unknown: '19.200',
      nr_2_total_offchart: '19.200',
      nr_3_total_high: '12.000',
      nr_3_total_unknown: '19.200',
      age_unknown: {'en':'unknown','nl':'onbekend','de':'unbekannt'},
      deceased_unknown: {'en':'unknown','nl':'onbekend','de':'unbekannt'},
      origin_unknown: {'en':'unknown','nl':'onbekend','de':'unbekannt'},
      origin_offchart: {'en':'outside of map','nl':'buiten de kaart','de':'außerhalb der Karte'},
      age_deceased_title: {'nl':'Leeftijd van overlijden','de':'Alter des todes','en':'Age of death'},
      origin_location_title: {'nl':'Eerste begraaflocatie','de':'Erste grabstätte','en':'First burial site'},
      date_deceased_title: {'nl':'Datum van overlijden','de':'Sterbedatum','en':'Date of death'},
      datavisualisation_1_title: {},
      datavisualisation_2_title: {},
      datavisualisation_3_title: {},
      hotspots_temp:{
        'temp_1':{
          'id': 0,
          'color': '#C1004F',
          'posy': 845,
          'posx': 387,
          'title': {
            'nl':'Hotspot Titel 1',
            'de':'Hotspot-Titel 1',
            'en':'Hotspot Title 1'
          },
          'text':{
            'nl':'Deze operatie was een geallieerd offensief in september 1944, om de Duitse verdedigingslinie langs de Rijn te omzeilen. Luchtlandingstroepen moesten strategische bruggen veroveren. De operatie was uiteindelijk een mislukking omdat een cruciale brug bij Arnhem niet behouden kon worden. Aan beide kanten vielen duizenden slachtoffers.',
            'de':'Diese Operation war eine alliierte Offensive im September 1944, um die deutsche Verteidigungslinie entlang des Rheins zu umgehen. Luftlandetruppen mussten strategische Brücken erobern. Die Operation scheiterte schließlich, weil eine wichtige Brücke bei Arnheim nicht erhalten werden konnte. Tausende Opfer wurden auf beiden Seiten getötet.',
            'en':'This operation was an Allied offensive in September 1944, to bypass the German defense line along the Rhine. Airborne troops had to capture strategic bridges. The operation was ultimately a failure because a crucial bridge at Arnhem could not be preserved. Thousands of victims were killed on both sides.'
          }
        },
        'temp_2':{
          'id': 0,
          'color': '#9D09DB',
          'posy': 845,
          'posx': 820,
          'title': {
            'nl':'Hotspot Titel 2',
            'de':'Hotspot-Titel 2',
            'en':'Hotspot Title 2'
          },
          'text':{
            'nl':'Deze operatie was een geallieerd offensief in september 1944, om de Duitse verdedigingslinie langs de Rijn te omzeilen. Luchtlandingstroepen moesten strategische bruggen veroveren. De operatie was uiteindelijk een mislukking omdat een cruciale brug bij Arnhem niet behouden kon worden. Aan beide kanten vielen duizenden slachtoffers.',
            'de':'Diese Operation war eine alliierte Offensive im September 1944, um die deutsche Verteidigungslinie entlang des Rheins zu umgehen. Luftlandetruppen mussten strategische Brücken erobern. Die Operation scheiterte schließlich, weil eine wichtige Brücke bei Arnheim nicht erhalten werden konnte. Tausende Opfer wurden auf beiden Seiten getötet.',
            'en':'This operation was an Allied offensive in September 1944, to bypass the German defense line along the Rhine. Airborne troops had to capture strategic bridges. The operation was ultimately a failure because a crucial bridge at Arnhem could not be preserved. Thousands of victims were killed on both sides.'
          }
        },
        'temp_3':{
          'id': 0,
          'color': '#069ACE',
          'posy': 845,
          'posx': 820,
          'title': {
            'nl':'Hotspot Titel 3',
            'de':'Hotspot-Titel 3',
            'en':'Hotspot Title 3'
          },
          'text':{
            'nl':'Deze operatie was een geallieerd offensief in september 1944, om de Duitse verdedigingslinie langs de Rijn te omzeilen. Luchtlandingstroepen moesten strategische bruggen veroveren. De operatie was uiteindelijk een mislukking omdat een cruciale brug bij Arnhem niet behouden kon worden. Aan beide kanten vielen duizenden slachtoffers.',
            'de':'Diese Operation war eine alliierte Offensive im September 1944, um die deutsche Verteidigungslinie entlang des Rheins zu umgehen. Luftlandetruppen mussten strategische Brücken erobern. Die Operation scheiterte schließlich, weil eine wichtige Brücke bei Arnheim nicht erhalten werden konnte. Tausende Opfer wurden auf beiden Seiten getötet.',
            'en':'This operation was an Allied offensive in September 1944, to bypass the German defense line along the Rhine. Airborne troops had to capture strategic bridges. The operation was ultimately a failure because a crucial bridge at Arnhem could not be preserved. Thousands of victims were killed on both sides.'
          }
        },
      },
      hotspots: {'age':[], 'origin':[], 'deceased':[]}
    }
  },
  mounted() {

    this.hotspots = globals.hotspots
    //console.log('hotspots---------')
    //console.log(this.hotspots)

    lv.fields_translate = ['datavisualisation_1_title','datavisualisation_2_title','datavisualisation_3_title']
    lv.fields_translate.push('age_unknown','origin_unknown','origin_offchart','age_deceased_title','origin_location_title','date_deceased_title','deceased_unknown')
    for (const val of lv.fields_translate) {
      this[val] = {}
      this[val]['en'] = globals.microtranslations[val]['text_en']
      this[val]['nl'] = globals.microtranslations[val]['text_nl']
      this[val]['de'] = globals.microtranslations[val]['text_de']
    }

    EventBus.$on('calc_age', (e) => {
      this.nr_1_total_high_age = e.high
      this.nr_1_total_unknown = e.unknown
      //console.log('...peak...'+e.peak)
    })

    EventBus.$on('calc_origin', (e) => {
      this.nr_2_total_unknown = e.unknown
      this.nr_2_total_offchart = e.uncharted
    })

    EventBus.$on('calc_deceased', (e) => {
      this.nr_3_total_high = e.high
      this.nr_3_total_unknown = e.unknown
      //console.log('...peak...'+e.peak)
    })

    EventBus.$on('graph_carrousel_start', (e) => {
      this.go(globals.carrousel_cur)
    })

    EventBus.$on('graph_carrousel_from_1_to_2', (e) => {
      this.done()
      gsap.delayedCall(0.6, this.signal_crosses,['1_to_2'])
      gsap.delayedCall(6, this.go,[2])
    })    
    EventBus.$on('graph_carrousel_from_2_to_3', (e) => {
      gsap.to('#mapwrapper',{duration:1, opacity:0, display:'none', ease:'power3.inOut'})
      this.done()
      gsap.delayedCall(0.6, this.signal_crosses,['2_to_3'])
      gsap.delayedCall(6, this.go,[3])
    })    
    EventBus.$on('graph_carrousel_from_2_to_1', (e) => {
      gsap.to('#mapwrapper',{duration:1, opacity:0, display:'none', ease:'power3.inOut'})
      this.done()
      gsap.delayedCall(0.6, this.signal_crosses,['2_to_1'])
      gsap.delayedCall(6, this.go,[1])
    })    
    EventBus.$on('graph_carrousel_from_3_to_2', (e) => {
      this.done()
      gsap.delayedCall(0.6, this.signal_crosses,['3_to_2'])
      gsap.delayedCall(6, this.go,[2])
    })    

    EventBus.$on('graph_carrousel_from_1_to_3', (e) => {
      this.done()
      gsap.delayedCall(0.6, this.signal_crosses,['1_to_3'])
      gsap.delayedCall(6, this.go,[3])
    })    
    EventBus.$on('graph_carrousel_from_3_to_1', (e) => {
      this.done()
      gsap.delayedCall(0.6, this.signal_crosses,['3_to_1'])
      gsap.delayedCall(6, this.go,[1])
    })    

    EventBus.$on('carrousel_circle_play', (e) => {
      this.go(e)
    })
    EventBus.$on('carrousel_circle_done', (e) => {
      this.done(e)
    })
    EventBus.$on('carrousel_circle_pause', (e) => {
      this.pause(e)
    })
    EventBus.$on('carrousel_circle_resume', (e) => {
      this.resume(e)
    })
    EventBus.$on('carrousel_circle_reset', (e) => {
      this.done(e)
    })
    EventBus.$on('carrousel_clicked', (e) => {
      this.done(e)
    })      
  },
  methods:{
    go(w){
      switch (w) {
        case 1:
          EventBus.$emit('cross_age_years')     
          break;
        case 2:
          EventBus.$emit('cross_origin')
          break;
        case 3:
          EventBus.$emit('cross_deceased_months')
          break;
      }
      lv.targ = '.igraph_' + w + '_ui_el'
      lv.stg = gsap.set(lv.targ,{opacity:0, display:'block'})
      lv.targ = '#igraph_' + w
      lv.ani = gsap.to(lv.targ,{delay:0.6, duration: 0.6, opacity:1, display:'block', ease:'power2.inOut'})
      lv.targ = '.igraph_' + w + '_ui_el'
      lv.stg = gsap.to(lv.targ,{delay:1, duration: 0.6, opacity:1, ease:'power2.inOut', stagger: 0.4, onComplete:()=>{
        EventBus.$emit('graph_carrousel_pagination_enable')
      }})
    },
    done(){
      lv.targ = '.infograph_wrap'
      lv.ani = gsap.to(lv.targ,{duration: 0.6, opacity:0, display:'none', ease:'power2.inOut'})
    },
    pause(w){
      lv.ani.pause()
    },
    resume(w){
      lv.ani.resume()
    },
    reset(w){
    },
    signal_crosses(w){
      EventBus.$emit('crosses_signal',w)
    }
  }
}
</script>

<style>
  .infograph_wrap{
    opacity: 0;
    display: none;
  }
  .hotspots_btns_wrap{
    position: absolute;
    width: 100%;
    left:0px;
    bottom: 140px;
  }
  .text_inf1{
    color:#fff;
  }
  #igraph_1_title{
    position: absolute;
    top: 564px;
    left: 1163px;
  }
  #igraph_1_nr_high{
    position: absolute;
    top: 65px;
    right: 1339px;
  }
  .text_nr_inf1{
    color: #fff;
    font-size: 30px;
    letter-spacing: -1.7px;
  }
  .igraph_line{
    width: 75px;
    border-bottom: 1px dashed #B5B5B5;
    position: relative;
    top: -6px;
    margin-left: 10px;
  }
  #igraph_1_nr_unknown{
    position: absolute;
    top: 95px;
    left: 1497px;
  }
  .text_nr_inf2{
    font-size: 18px;
    position: relative;
    top: -10px;
    left: -2px;
    letter-spacing: 0px;
  }
  .text_inf1_2{
    color:#fff;
    letter-spacing: 2px;
  }
  #igraph_1_graph{
    position: absolute;
    top: 796px;
    left: 87px;
  }

  #igraph_2_title{
    position: absolute;
    top: 564px;
    left: 1163px;
  }
  #igraph_2_nr_unknown{
    position: absolute;
    top: 95px;
    left: 1497px;
  }
  #igraph_2_offchart{
    position: absolute;
    top: 210px;
    left: 1497px;
  }

  #igraph_3_title{
    position: absolute;
    top: 564px;
    left: 1163px;
  }
  #igraph_3_nr_high{
    position: absolute;
    top: 66px;
    left: 943px;
  }
  #igraph_3_nr_unknown{
    position: absolute;
    top: 95px;
    left: 1497px;
  }
  #igraph_3_graph{
    position: absolute;
    top: 796px;
    left: 87px;
  }
  .igraph_line_2{
    width: 75px;
    border-bottom: 1px dashed #B5B5B5;
    position: relative;
    top: -6px;
    margin-right: 10px;
  }
  
</style>

