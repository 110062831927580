<template>
    <span>
      <span v-show="(globals.language == 'nl')">
        {{obj.nl}}
      </span>
      <span v-show="(globals.language == 'de')">
        {{obj.de}}
      </span>
      <span v-show="(globals.language == 'en')">
        {{obj.en}}
      </span>
    </span>
</template>

<script>
import globals from '@/modules/globals'

export default {
  name: "translations",
  props: ['obj'],
  data() {
    return {
      globals: globals
    }
  },
  mounted() {
  }
}
</script>

<style>

</style>

