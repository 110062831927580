<template>

  <div class="absolute hotspot_dot_wrap pointer-events-auto" :style="{ left: obj.position_x + 'px', top: obj.position_y + 'px' }">
    <div class="hotspot_dot bg-teel text-white">
      <svg class="doticon pos-center" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
    </div>
    <div class="pos-g cursor-pointer" @click="hotspot_click(obj.id)"></div>
  </div>

</template>

<script>
import gsap from 'gsap'
import globals from '@/modules/globals'
import { EventBus } from '@/modules/event_bus'


var lv = {}

export default {
  name: "btn_hotspot",
  components: {
  },
  props: ['obj'],
  data() {
    return {
    }
  },
  mounted() {
  },
  methods:{
    hotspot_click(i){
      if(!globals.carrousel_click_allowed){
        return
      }
      EventBus.$emit('modal_hotspot_open', this.obj)
    }
  }
}
</script>

<style>
.hotspot_dot{
  width: 39px;
  height: 39px;
  border-radius:50%;
}
.doticon{
  width:27px;
  height: 27px;
}
</style>

