<template>
    <div>
      <div v-show="(globals.language == 'nl')" v-html="obj.nl"></div>
      <div v-show="(globals.language == 'de')" v-html="obj.de"></div>
      <div v-show="(globals.language == 'en')" v-html="obj.en"></div>
    </div>
</template>

<script>
import globals from '@/modules/globals'

export default {
  name: "translations",
  props: ['obj'],
  data() {
    return {
      globals: globals
    }
  },
  mounted() {
  }
}
</script>

<style>

</style>

