<template>

  <div class="pos-g hidden opacity-0" id="modal_hotspot">
    <div class="pos-g bg-black bg-opacity-25"></div>
    <div class="pos-g w-slat">
      <div class="pos-center font-sans modal_hotspot_content_wrap overflow-hidden" id="modal_hotspot_content" v-click-outside="close">

        <div class="modal_hotspot_hdr">
          <div class="font-sans modal_hotspot_hdr_title">
            <translations :obj="obj.title" />
          </div>
          <div class="modal_close">
            <img src="@/assets/img/ui/close_teal.png" />
            <div class="pos-center cursor-pointer click_hit" @click="close"></div>
          </div>
          <div class="bordertitle"></div>
        </div>

        <div class="modal_hotspot_text_hold" id="modal_hotspot_text">
          <div class="pos-g modal_hotspot_text_padding" id="modal_hotspot_text_div">

            <div class="modal_hotspot_text text-aswald relative h-full">
              <div class="overflow-hidden" style="margin-bottom:30px;" v-if="obj.image">
                <img :src="assets_path+obj.image.sm" class="w-full object-cover" style="height:224px;" />
              </div>
              <div class="modal_hotspot_text_limit modal_hotspot_breadtext overflow-hidden" style="height:178px;">
                <translations_html :obj="obj.text" />
              </div>

              <div class="modal_hotspot_article_btn border border-brick rounded-full p-2 px-4" v-if="obj.article" :class="clicked_article?'bg-white text-brick ':'bg-brick text-white'" >
                <translations :obj="read_article" />
                <div class="pos-center click_hit cursor-pointer" @click="article_click(obj.article)"></div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>

</template>

<script>
import gsap from 'gsap'
import globals from '@/modules/globals'
import { EventBus } from '@/modules/event_bus'

import translations from '@/components/translations'
import translations_html from '@/components/translations_html'

var lv = {}

export default {
  name: "modal_hotspot",
  components: {
    translations,
    translations_html
  },
  data() {
    return {
      obj: {
        'title': {
          'nl': '',
          'de': '',
          'en': '',
        },
        'text': {
          'nl': '',
          'de': '',
          'en': '',
        },
      },
      opened: false,
      clicked_article: false,
      assets_path: globals.assets_path,
      article_chosen: '',
      read_article: {}
    }
  },
  mounted() {


    lv.fields_translate = ['read_article']
    for (const val of lv.fields_translate) {
      this[val] = {}
      this[val]['en'] = globals.microtranslations[val]['text_en']
      this[val]['nl'] = globals.microtranslations[val]['text_nl']
      this[val]['de'] = globals.microtranslations[val]['text_de']
    }

    
    EventBus.$on('modal_hotspot_open', (e) => {
      this.obj = e
      this.open()
      EventBus.$emit('carrousel_pause')
    })
    EventBus.$on('modal_hotspot_close', (e) => {
      this.close()
    })
    EventBus.$on('attract_mode_active', (e) => {
      this.close()
    })
  },
  methods:{
    article_click(i){
      //console.log('article_click ' + i)
      this.clicked_article = true
      this.article_chosen = i
      this.close()
    },
    open(){
      lv.targ = '#modal_hotspot'
      gsap.to(lv.targ,{duration:0.6, opacity:1, display: 'block', ease:'power4.inOut', onComplete: () =>{
        this.opened = true
      }})
      /*
      lv.targ = '#modal_hotspot_content'
      gsap.set(lv.targ,{width:0})
      gsap.to(lv.targ,{delay:0.2, duration:0.5, width:468, ease:'power3.inOut'})
      lv.targ = '#modal_hotspot_text'
      gsap.set(lv.targ,{height:0})
      gsap.to(lv.targ,{delay:0.6, duration:0.5, height:400, ease:'power3.inOut'})
      lv.targ = '#modal_hotspot_text_div'
      gsap.set(lv.targ,{opacity:0, display:'none'})
      gsap.to(lv.targ,{delay:0.6, duration:0.5, opacity:1, display: 'block', ease:'power3.inOut'})
      */
    },
    close(){
      if(!this.opened){
        return
      }
      lv.targ = '#modal_hotspot'
      gsap.to(lv.targ,{duration:0.6, opacity:0, display: 'none', ease:'power4.inOut', onComplete: () => {
        this.opened = false   
        if(this.clicked_article){
          //console.log('clicked article ' + this.article_chosen)
          EventBus.$emit('article_from_hotspot', this.article_chosen)
          EventBus.$emit('navigate_slat', 3)
          this.clicked_article = false
          return
        }
        //EventBus.$emit('carrousel_resume')
      }})
    }
  }
}
</script>

<style>
.modal_hotspot_content_wrap{
  width: 468px;
  background: #fff;;
  border: 1px solid #707070;
}
.modal_hotspot_hdr{
  /*
  background: transparent linear-gradient(180deg, #7A1010 0%, #510101 100%) 0% 0% no-repeat padding-box;
  border-bottom: 1px solid #707070;
  height: 66px;
  white-space: nowrap;
  */
  position: relative;
  padding: 18px 30px;
  overflow: hidden;
}
.modal_hotspot_hdr_title{
  font-size: 28px;
  font-weight: bold;
  color:#000;
  line-height: 1;
  padding-right: 13px;
}
.modal_close{
  position: absolute;
  right:5px;
  top:0px;
  padding: 23px;
}
.modal_hotspot_text_hold{
  height: 545px;
  position: relative;
}
.modal_hotspot_text_padding{
  padding: 30px;
}
.modal_hotspot_text{
  font-size: 18px;
  position: relative;
}
.modal_hotspot_article_btn{
  position: absolute;
  bottom:0px;
  left:0px;
  font-size: 15px;
  text-transform: uppercase;
}
.modal_hotspot_text_limit{
  height: 300px;
}
.click_hit{
  width: 125%;
  height: 125%;
}
.modal_hotspot_breadtext{
  font-size: 18px;
  color: #182321;
  line-height: 1.25;
}
.bordertitle{
  height: 20px;
  border-bottom: 1px solid #8CB1AA;
}

</style>
