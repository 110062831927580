<template>
  <div class="pos-g hidden opacity-0" id="modal_search">
    <div class="pos-g bg-black bg-opacity-25"></div>
    <div class="pos-g w-slat">
      <div
        class="modal_search_content_wrap overflow-hidden px-6 py-4"
        id="modal_search_content"
        v-click-outside="click_close"
      >
        <div class="flex justify-between pb-3 border-b border-jitterburg mb-4">
          <div class="modal_search_hdr_title modal_name" v-if="obj.name">
            {{ obj.name }}
          </div>
          <div @click="click_close" class="modal_search_close">
            <svg
              stroke="#8CB1AA"
              stroke-width="3px"
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="cross">
                <line x1="7" x2="25" y1="7" y2="25" />
                <line x1="7" x2="25" y1="25" y2="7" />
              </g>
            </svg>
          </div>
        </div>

        <div
          class="modal_search_text_hold grid grid-flow-col gap-8 border-b border-jitterburg pb-2"
        >
          <div class="modal_search_info">
            <div v-if="fields">
              <div
                v-for="(info, index) in fields"
                :key="index"
                class="mb-2"
                id="modal_search_text"
              >
                <div class="text-xs text-jitterburg uppercase">
                  {{ info.title }}
                </div>
                <div class="text-base">
                  {{ info.text }}
                </div>
              </div>
            </div>
          </div>
          <div class="modal_search_text bio_text" v-if="bio_text">
            <div class="pb-4 pr-3">
              <div v-html="bio_text">
              </div>
            </div>
          </div>
          <div class="modal_search_carousel" style="width:231px; min-height:460px;" v-if="bio.files">
            <swiper
              v-if="opened"
              class="swiper search-swiper"
              :options="swiperOptions"
              @ready="handleSwiperReadied"
            >
              <swiper-slide
                class="swiper-slide mb-16"
                v-for="(item, index) in bio.files"
                :key="index"
              >
                <div class="mb-4 text-center" style="height:290px;">
                  <a data-fancybox="gallery" class="inline-block" :href="assets_path+item.file" :data-caption="item.caption[globals.language]" >
                    <img :src="assets_path+item.sm" />
                  </a>                  
                </div>
                <div class="text-xs italic mb-4" v-if="item.caption[globals.language]">
                  {{ item.caption[globals.language] }}
                </div>
              </swiper-slide>
              <div class="swiper-pagination absolute bottom-0" slot="pagination" v-if="bio.files" v-show="bio.files.length > 1"></div>
            </swiper>
            <div class="mb-2 pagination_arrows" style="position: relative; top: -38px; z-index: 10;" v-if="bio.files">
              <div class="flex" v-show="bio.files.length > 1">
                <div class="flex items-center justify-center btn_pagi_find cursor-pointer" @click="prevSlide">
                  <svg class="w-4 h-4" fill="none" stroke="#5EB5A5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path></svg>
                </div>
                <div class="ml-auto"></div>
                <div class="flex items-center justify-center btn_pagi_find cursor-pointer" @click="nextSlide">
                  <svg class="w-4 h-4" fill="none" stroke="#5EB5A5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pt-4">
          <div v-if="tags">
            <div class="text-jitterburg inline-block mr-2">
              <translations :obj="tags_string" />:
            </div>
            <div class="inline-block" v-for="(item, index) in tags" :key="index">
              <div class="text-brick text-xs inline-block cursor-pointer mr-2" @click="click_tag(item.id)">
                #{{item.title}}
              </div>
              <span class="mr-2" v-if="index > 0 && index < tags.length">/</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import gsap from "gsap";
  import globals from "@/modules/globals";
  import { EventBus } from "@/modules/event_bus";
  import * as cheet from 'cheet.js'

  import translations from "@/components/translations";

  import { Swiper, SwiperSlide } from "vue-awesome-swiper";
  import "swiper/css/swiper.css";
  import { Fancybox } from "@fancyapps/ui";
  import "@fancyapps/ui/dist/fancybox.css";
  // import translations from "@/components/translations";

  var lv = {}
  lv.thisVue = null
  lv.fancybox = null

  export default {
    name: "modal_search",
    components: {
      // translations,
      Swiper,
      SwiperSlide,
      translations
    },
    data() {
      return {
        swiperOptions: {
          spaceBetween: 5,
          effect: "slide",
          speed: 700,
          loop: true,
          pagination: {
            el: ".swiper-pagination",
          },
        },
        test_carousel: [],
        globals: globals,
        test_obj: {},
        obj: {},
        bio:{},
        bio_text: null,
        assets_path: globals.assets_path,
        fields: null,
        opened: false,
        clicked_article: false,
        tags_string: { en: "Read articles on the topics", nl: "Lees artikelen over de onderwerpen", de: "Lesen Sie Artikel zu den Themen" },
        tags: null,
        gallery_open: false,
      };
    },
    mounted() {
      lv.thisVue  = this

      EventBus.$on("modal_search_open", (e) => {
        this.populate(e)
      });
      EventBus.$on("modal_search_close", (e) => {
        this.close();
      })
      EventBus.$on("attract_mode_active", (e) => {
        Fancybox.close()
        //Fancybox.destroy()
        this.close();
      })
      EventBus.$on("navigate_slat", (e) => {
        Fancybox.close()
        //Fancybox.destroy()
        this.close();
      })


    },
    methods: {
      populate(e){
        console.log(e)

        this.obj = {}
        this.fields = {}
        this.tags = null
        this.bio = {}
        this.bio.files = []
        this.bio_text = null

        this.obj.name = e.name

        lv.fields_unordered = {}

        lv.brg_str = ''
        if(e['block']){
          lv.brg_str += e['block']
        }else{
          lv.brg_str += ' '
        }
        lv.brg_str += '-'
        if(e['row']){
          lv.brg_str += e['row']
        }else{
          lv.brg_str += ' '
        }
        lv.brg_str += '-'
        if(e['grave']){
          lv.brg_str += e['grave']
        }else{
          lv.brg_str += ' '
        }
        lv.val = 'block_row_grave'
        lv.fields_unordered[lv.val] = {
          title: globals.fields_names[lv.val][globals.language],
          text: lv.brg_str
        }
        
        lv.fields_clone = ['age','birth_location','deceased_location','origin_location']
        for (const val of lv.fields_clone) {
          if(e[val]){
            lv.fields_unordered[val] = {
              title: globals.fields_names[val][globals.language],
              text: e[val]
            }
          }
        }

        lv.fields_combine_dates = ['birth_date','deceased_date','transmission_date']
        for (const val of lv.fields_combine_dates) {
          if(e[val + '_year']){
            lv.fields_unordered[val] = {
              title: globals.fields_names[val][globals.language],
              text: e[val + '_day'] + '-' + e[val + '_month'] + '-' + e[val + '_year']
            }
          }
        }

        lv.fields_relations = ['nationality','cause_of_death','military_rank','army_unit']
        for (const val of lv.fields_relations) {
          if(e[val]){
            lv.fobj = globals.fields_data[val].find(o => o.id == e[val])
            lv.fallback = ''
            if(lv.fobj['en']){
              lv.fallback = lv.fobj['en']
            }
            lv.fieldname = lv.fallback
            if(lv.fobj[globals.language]){
              lv.fieldname = lv.fobj[globals.language]
            }
            lv.fields_unordered[val] = {
              title: globals.fields_names[val][globals.language],
              text: lv.fieldname
            }
          }
        }        

        lv.fields_order = ['block_row_grave','birth_date','deceased_date','birth_location','deceased_location']
        lv.fields_order.push('nationality','cause_of_death','military_rank','army_unit','transmission_date','origin_location')
        lv.fields_ordered = {}
        for (const val of lv.fields_order) {
          //console.log(val)
          //console.log(lv.fields_unordered[val])
          if(lv.fields_unordered[val]){
            lv.fields_ordered[val] = lv.fields_unordered[val]
          }
        }
        
        this.fields = lv.fields_ordered

        if(e.tags.length > 0){
          this.tags = []
          for (const val of e.tags) {
            if(val){
              lv.fobj = globals.fields_data['tags'].find(o => o.id == val)
              lv.fallback = ''
              if(lv.fobj['en']){
                lv.fallback = lv.fobj['en']
              }
              lv.fieldname = lv.fallback
              if(lv.fobj[globals.language]){
                lv.fieldname = lv.fobj[globals.language]
              }
              this.tags.push({title: lv.fieldname, id: val})
            }
          }
        }

        fetch('json/data/bios/bio_' + e.id + '.json?v=' + globals.content_version)
        .then(response => response.json())
        .then(function(data){
          if(data){
            //console.log(data)
            lv.thisVue.bio = data
            lv.thisVue.bio_text = data.text[globals.language]

            lv.thisVue.fancybox_go()
          }
        }).catch((error) => {
          console.log('No Bio Found');
          console.log(error);
        })

        gsap.set('.pagination_arrows',{opacity:0})

        this.open()
      },
      fancybox_go(){

        Fancybox.bind('[data-fancybox="gallery"]', {
          on: {
            done: () => {
              this.gallery_open = true
              //console.log('galler_search_opened')
            },
            destroy: () => {
              //console.log('galler_search_closed')
              this.gallery_open = false
            },
          }
        })

      },
      handleSwiperReadied(swiper) {
        gsap.to('.modal_search_carousel',{duration: 0.3, opacity:1, ease:'power4.inOut'})
        gsap.to('.pagination_arrows',{duration: 0.3, opacity:1, ease:'power4.inOut'})
      },
      nextSlide() {
        const swiper = document.querySelector(".search-swiper").swiper;        
        swiper.slideNext();
      },
      prevSlide() {
        const swiper = document.querySelector(".search-swiper").swiper;
        swiper.slidePrev();
      },
      article_click(i) {
        console.log("article_click " + i);
        this.clicked_article = true;
        this.close();
      },
      open() {
        lv.targ = "#modal_search";
        gsap.to(lv.targ, {
          duration: 0.6,
          opacity: 1,
          display: "block",
          ease: "power4.inOut",
          onComplete: () => {
            this.opened = true;
          },
        });
        lv.targ = "#modal_search_content";
      },
      close() {
        if (!this.opened) {
          return;
        }
        lv.targ = "#modal_search";
        gsap.to(lv.targ, {
          duration: 0.6,
          opacity: 0,
          display: "none",
          ease: "power4.inOut",
          onComplete: () => {
            this.opened = false;
          },
        });
      },
      click_close(){
        if(this.gallery_open){
          return
        }
        this.close()
      },
      click_tag(e){
        this.close()
        gsap.delayedCall(0.5, ()=>{            
          EventBus.$emit('navigate_tag', e)
        })
      }
    },
  };

  cheet('f c l o s e', function () {
    Fancybox.close()
  })
</script>

<style scoped>
  .modal_search_content_wrap {
    min-width: 350px;
    /* width: 969px; */
    background: #fff;
    border: 1px solid #707070;
    transform: translate(-25%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
  }
  .modal_search_hdr {
    border-bottom: 1px solid #707070;
    height: 66px;
    position: relative;
    padding: 18px 30px;
    white-space: nowrap;
    overflow: hidden;
  }
  .modal_search_hdr_title {
    font-size: 28px;
    font-weight: bold;
    color: #fff;
    line-height: 1;
    margin-right: 20px;
  }
  .modal_search_close {
    width: 28px;
    height: 28px;
    cursor: pointer;
  }
  .modal_search_text_hold {
    min-height: 400px;
    position: relative;
  }
  /*
  .modal_search_info {
    min-width: 260px;
  }
  */
  .modal_search_text {
    min-width: 400px;
  }
  .modal_search_carousel {
    min-width: 230px;
    opacity: 0;
  }
  .pagination_arrows {
    opacity: 0;
  }
  .carousel-image {
    max-height: 290px;
  }

  .bio_text{
    max-height: 660px;
    overflow: auto;
    font-size: 1rem;
  }


  .modal_name{
    color:#182321;
    font: italic normal normal 27px/39px "ff-tisa-web-pro";
    letter-spacing: 0px;
  }
  .bio_img{
    width: 231px;
    height: 231px;
  }

</style>
