<template>
  <div class="pos-g bg-gray-800">
    <div class="wrapmap">
      <img src="@/assets/img/map_nl.png" />

      <div v-for="(item, index) in mapitems" :key="index" class="rounded-full w-3 h-3 absolute bg-white" :style="{top:item.y+'px', left:item.x+'px'}" :data-name="item.name">
      </div>

    </div>
  </div>
</template>

<script>
import gsap from 'gsap'
import globals from '@/modules/globals'
import { EventBus } from '@/modules/event_bus'

var lv = {}
lv.thisVue = null

// Map size
lv.map_width = 831
lv.map_height = 982
// Bounding box Holland
// top left: 53.54601529135908, 3.3787755095139054
// top right: 53.54052571211311, 7.198839016203713
// bottom left: 50.770062107356075, 3.374156327522744
// bottom right: 50.7759043990952, 7.212696562177196

export default {
  name: "maptest",
  props: [],
  data() {
    return {
      mapitems:[
        {
          'name': 'test',
          'x' : -1000,
          'y' : -1000
        }
      ]
    }
  },
  mounted() {
    //
    lv.places = {
      'away':{
        'lat': '51.4896830794357',
        'lon': '7.6951015606735975'
      },
      'amsterdam':{
        'lat': '52.3666646916739',
        'lon': '4.919435783394578'
      },
      'texelwad':{
        'lat': '52.96880808345336',
        'lon': '4.677732930434336'
      },
      'neus':{
        'lat': '52.64728284041096',
        'lon': '7.027219937631446'
      },
      'priefel':{
        'lat': '51.18370222990879',
        'lon': '6.159300048644517'
      },
      'leftbottom':{
        'lat': '51.53468607940344',
        'lon': '3.4381793582293807'
      },
      'top':{
        'lat': '53.47164366134995',
        'lon': '6.04378678234261'
      },
      'bottomrightleft':{
        'lat': '50.76079244159444,',
        'lon': '5.690319725421469'
      },
    }
    /*
    //
    lv.places = {
      'botlef':{
        'lat': '51.369647380739735',
        'lon': '3.3706326151453068'
      },
      'botrig':{
        'lat': '50.7571336734474',
        'lon': '6.0172403393915985'
      },
      'toplef':{
        'lat': '53.182211983984',
        'lon': '4.849841815558295'
      },
      'toprig':{
        'lat': '53.438273171852416',
        'lon': '6.877304073623171'
      },
    }
    */
    
    for (const prop in lv.places) {
      lv[lv.places[prop].name] = latlngToScreenXY(lv.places[prop].lat, lv.places[prop].lon)
      this.mapitems.push({'name':prop,'x':lv[lv.places[prop].name].x,'y':lv[lv.places[prop].name].y})
      //console.log('1. have latlon: ' + lv.places[prop].lat + ',' + lv.places[prop].lon)
      //console.log('1. got x y: ' + lv[lv.places[prop].name].x + ',' + lv[lv.places[prop].name].y)
    }

  },
  methods:{
    geoToPixel(lat, lon){
      lat = parseInt(lat)
      lon = parseInt(lon)
      lv.x = lv.map_width * ((lv.westLon-lon)/(lv.westLon-lv.eastLon))
      lv.y = lv.map_height * ((lv.northLat-lat)/(lv.northLat-lv.southLat))
      return [lv.x, lv.y]
    }
  }
};

var p0 = {
    scrX: 0,
    scrY: 0,
    lat: 53.54601529135908,
    lng: 3.3787755095139054
}
var p1 = {
    scrX: lv.map_width - 8,
    scrY: lv.map_height - 12,
    lat: 50.7759043990952,
    lng: 7.212696562177196
}
var radius = 6371

function latlngToGlobalXY(lat, lng){
    let x = radius*lng*Math.cos((p0.lat + p1.lat)/2);
    let y = radius*lat;
    return {x: x, y: y}
}
p0.pos = latlngToGlobalXY(p0.lat, p0.lng);
p1.pos = latlngToGlobalXY(p1.lat, p1.lng);

function latlngToScreenXY(lat, lng){
    let pos = latlngToGlobalXY(lat, lng);
    pos.perX = ((pos.x-p0.pos.x)/(p1.pos.x - p0.pos.x));
    pos.perY = ((pos.y-p0.pos.y)/(p1.pos.y - p0.pos.y));
    return {
        x: p0.scrX + (p1.scrX - p0.scrX)*pos.perX,
        y: p0.scrY + (p1.scrY - p0.scrY)*pos.perY
    }
}
//https://stackoverflow.com/a/53827343/15436154

</script>

<style scoped>
.wrapmap{
  position: absolute;
  top:40px;
  left:200px;
}
</style>
