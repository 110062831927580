<template>
  <div class="pos-g font-sans bg-kettle">

    <div class="pos-center res_main overflow-hidden" id="wrap_main">

      <div class="pos-g pointer-events-none" id="slat_1_bg_2"></div>

      <div class="pos-g">
        <crosses />
        <div class="pos-g bg-kettle hidden opacity-0" id="hide_slat_1"></div>
      </div>

      <div class="pos-g" id="wrap_slats">
        <div class="absolute res_main overflow-hidden" id="slat_1">
          <div class="pos-g cursor-pointer" id="btn_slat_1" @click="click_slat(1)">
            <div class="wrap_slat_btn">
              <img src="@/assets/img/ui/explore.png" />
            </div>
          </div>
          <div class="h-full" id="content_slat_1">
            <slat_explore />
          </div>
        </div>

        <div class="absolute res_main overflow-hidden" id="slat_2">
          <div class="pos-g cursor-pointer" id="btn_slat_2" @click="click_slat(2)">
            <div class="wrap_slat_btn">
              <img src="@/assets/img/ui/find.png" />
            </div>
          </div>
          <div class="h-full" id="content_slat_2">
            <slat_find />
          </div>
        </div>

        <div class="absolute res_main overflow-hidden" id="slat_3">
          <div class="pos-g cursor-pointer" id="btn_slat_3" @click="click_slat(3)">
            <div class="wrap_slat_btn">
              <img src="@/assets/img/ui/read.png" />
            </div>
          </div>
          <div class="h-full" id="content_slat_3">
            <slat_read />
          </div>
        </div>

        <div class="absolute res_main overflow-hidden" id="attract_mode_ui">
          <div class="wrap_play_btn" id="attract_play">
              <img src="@/assets/img/ui/play.png" class="centerx" />
          </div>
          <div class="pos-g cursor-pointer" id="btn_attract_mode" @click="click_attract"></div>
        </div>

        <modal_hotspot />
        <modal_search />
      </div>


      <maptest v-if="show_maptest" />

    </div>

    <menu_dev v-if="menudev_show" />
    <canvas id="scribbler" class=" pointer-events-none" width="480" height="270"></canvas>

  </div>
</template>


<script>
import globals from '@/modules/globals'
import { EventBus } from '@/modules/event_bus'
import control_main from '@/modules/control_main'

import slat_explore from '@/components/slat_explore'
import slat_find from '@/components/slat_find'
import slat_read from '@/components/slat_read'

import crosses from '@/components/crosses'
import menu_dev from '@/components/menu_dev'
import maptest from '@/components/maptest'

import modal_hotspot from '@/components/ui/modal_hotspot'
import modal_search from "@/components/ui/modal_search";


import gsap from 'gsap'
import * as cheet from 'cheet.js'

var lv = {}
lv.thisVue = null

lv.idle_time_sec = globals.idle_time_sec

export default {
  name: "app_main",
  components: {
    /*
    listview
    */
    slat_explore,
    slat_find,
    slat_read,
    crosses,
    menu_dev,
    maptest,
    modal_hotspot,
    modal_search,
  },
  beforeRouteEnter(to, from, next) {

    // -> /kiosk?k=a7d667b8ae181fd973d78b753c80cdda4782fa78

    lv.query_key = to.query.k
    //lv.query_key = 'a7d667b8ae181fd973d78b753c80cdda4782fa78'
    lv.kiosk_lock = 'a7d667b8ae181fd973d78b753c80cdda4782fa78'
    lv.allowed = false
    if(lv.query_key == lv.kiosk_lock){
      lv.allowed = true
      console.log('allowed')
    }else{
      console.log('not allowed')
    }

    function persons_all(){
      fetch('json/data/persons_all.json?v=' + globals.content_version)
      .then(response => response.json())
      .then(function(data){
        console.log('persons')
        console.log(data)
        globals.fields_data = data.fields
        globals.persons_data = data.items
        info_data()
      })
    }
    function info_data(){
      fetch('json/data/info_data.json?v=' + globals.content_version)
      .then(response => response.json())
      .then(function(data){
        console.log('info_data')
        console.log(data)
        globals.microtranslations = data.microtranslations
        globals.hotspots = data.hotspots
        globals.article_chapters = data.articles
        next()
      })
    }

    if(lv.allowed){
      persons_all()
    }

  },
  data() {
    return {
      show_maptest: false,
      menudev_show: globals.menudev_show
    }
  },
  mounted () {

    lv.thisVue = this

    //control_main.preloader_done()

    this.init()

    window.addEventListener('resize', resize);
    resize()

    gsap.to('#loading_string',{delay: 1, duration:1, opacity:0, display:'none', ease:'power3.inOut'})
    gsap.delayedCall(2,cinit_delayed)
    gsap.delayedCall(2.5, this.curtain_open)

    //gsap.delayedCall(1, this.curtain_open)

  },
  methods: {
    init(){
      lv.targ = '.engage_element'
      gsap.set(lv.targ, {opacity:0, display:'none'})
    },
    click_attract(){
      engage_mode()
    },
    click_slat(who){
      EventBus.$emit('navigate_slat', who)
    },
    curtain_open(){
      EventBus.$emit('curtain_open')
    }
  }
}

// ------------------------- attract / engage mode -------------------------

lv.mode_cur = 'attract'

EventBus.$on('attract_mode', (e) => {
  attract_mode()
})

function engage_mode(){

  //EventBus.$emit('crosses_pause')
  EventBus.$emit('crosses_from_attract_to_engage')

  var tl_engage = gsap.timeline({
    onComplete: engage_ready
  })
  lv.targ = '#attract_play'
  tl_engage.to(lv.targ, {duration:0.2, scale:1.05, ease:'power3.out' })
  tl_engage.to(lv.targ, {duration:0.2, scale:1, ease:'power3.in' })
  lv.targ = '#attract_mode_ui'
  tl_engage.to(lv.targ, {duration:0.6, opacity:0, display:'none', ease:'power3.in' },'-=0.2')
  lv.targ = '.engage_element'
  tl_engage.to(lv.targ, {duration:0.6, opacity:1, display:'block', ease:'power3.in' },'-=0.3')
  lv.targ = 'slat_2'
  tl_engage.to('#' + lv.targ,{delay:0.3, duration:0.5, left:1724, ease:'power3.out' })
  lv.targ = 'slat_3'
  tl_engage.to('#' + lv.targ,{duration:0.5, left:1822, ease:'power3.out' },'-=0.4')

  idle_timer()

}
function engage_ready(){
  globals.carrousel_cur = 1
  //globals.carrousel_cur = 3
  lv.dc = gsap.delayedCall(3, graph_carrousel_start)
  lv.mode_cur = 'engage'
}

function graph_carrousel_start(){
  EventBus.$emit('graph_carrousel_start')
}

function attract_mode(){
  if(lv.mode_cur == 'attract'){
    return
  }
  gsap.globalTimeline.clear()
  gsap.to('#mapwrapper',{duration:0.6, opacity:0, display:'none', ease:'power3.inOut'})
  EventBus.$emit('modal_hotspot_close')
  EventBus.$emit("attract_mode_active")
  navigate_slat(1)
  gsap.delayedCall(0.2,attract_transit)
}
function attract_transit(){
  var tl_attract = gsap.timeline({
    onComplete: attract_ready
  })
  lv.targ = 'slat_3'
  tl_attract.to('#' + lv.targ,{duration:0.5, left:2018, ease:'power3.out' })
  lv.targ = 'slat_2'
  tl_attract.to('#' + lv.targ,{duration:0.5, left:1920, ease:'power3.out' },'-=0.4')
  lv.targ = '.engage_element'
  tl_attract.to(lv.targ, {duration:0.6, opacity:0, display:'none', ease:'power3.in' })
  lv.targ = '#attract_mode_ui'
  tl_attract.to(lv.targ, { duration:0.6, opacity:1, display:'block', ease:'power3.in' },'+=2')
}
function attract_ready(){
  EventBus.$emit('carrousel_reset')
  globals.language = 'nl'
  lv.mode_cur = 'attract'
}

document.body.addEventListener('pointerup', idle_reset, true)

function idle_timer(){
  gsap.delayedCall(lv.idle_time_sec, from_engage_to_attract)
}
function idle_reset(){
  //console.log('idle_reset')
  gsap.killTweensOf(from_engage_to_attract)
  idle_timer()
}
function from_engage_to_attract(){
    gsap.killTweensOf(attract_mode)
    attract_mode()
    gsap.delayedCall(1,cinit_delayed)
}
cheet('a t t r a c t', function () {
    from_engage_to_attract()
})
function cinit_delayed(){
  EventBus.$emit('cinit')
}

// ------------------------- slats -------------------------

lv.slat_busy = false
lv.slat_cur = 1

EventBus.$on('navigate_slat', (e) => {
  navigate_slat(e)
})

function navigate_slat(who){

  if(lv.slat_busy){
    return
  }
  slat_start()

  if(who == 1){
    lv.targ = 'slat_1'
    gsap.to('#content_' + lv.targ,{duration:0.6, opacity:1, display:'block', ease:'power3.inOut' })
    gsap.to('#btn_' + lv.targ,{duration:0.6, opacity:0, display:'none', ease:'power3.inOut' })
    gsap.to('#' + lv.targ + '_bg_2',{duration:0.6, opacity:1, ease:'power3.inOut' })
    lv.targ = '#canvas_wrap'
    //gsap.to(lv.targ,{duration:0.6, opacity:1, display:'block', ease:'power3.inOut' })
    lv.targ = '#hide_slat_1'
    gsap.to(lv.targ,{duration:0.6, opacity:0, display:'none', ease:'power3.inOut' })
    lv.targ = 'slat_2'
    gsap.to('#' + lv.targ,{duration:0.6, left:1724, ease:'power3.inOut' })
    gsap.to('#content_' + lv.targ,{duration:0.6, opacity:0, display:'none', ease:'power3.inOut' })
    gsap.to('#btn_' + lv.targ,{duration:0.6, opacity:1, display:'block', ease:'power3.inOut' })
    gsap.to('#' + lv.targ + '_bg_2',{duration:0.6, opacity:0, ease:'power3.inOut' })
    lv.targ = 'slat_3'
    gsap.to('#' + lv.targ,{duration:0.6, left:1822, ease:'power3.inOut' })
    gsap.to('#content_' + lv.targ,{duration:0.6, opacity:0, display:'none', ease:'power3.inOut' })
    gsap.to('#btn_' + lv.targ,{duration:0.6, opacity:1, display:'block', ease:'power3.inOut' })
    gsap.to('#' + lv.targ + '_bg_2',{duration:0.6, opacity:0, ease:'power3.inOut' })

    //EventBus.$emit('carrousel_resume')
  }

  if(who == 2){
    lv.targ = 'slat_1'
    gsap.to('#content_' + lv.targ,{duration:0.6, opacity:0, display:'none', ease:'power3.inOut' })
    gsap.to('#btn_' + lv.targ,{duration:0.6, opacity:1, display:'block', ease:'power3.inOut' })
    gsap.to('#' + lv.targ + '_bg_2',{duration:0.6, opacity:0, ease:'power3.inOut' })
    lv.targ = '#canvas_wrap'
    //gsap.to(lv.targ,{delay: 0.6, duration:0.6, opacity:0, display:'none', ease:'power3.inOut' })
    lv.targ = '#hide_slat_1'
    gsap.to(lv.targ,{duration:0.6, opacity:1, display:'block', ease:'power3.inOut' })
    lv.targ = 'slat_2'
    gsap.to('#' + lv.targ,{duration:0.6, left:98, ease:'power3.inOut' })
    gsap.to('#content_' + lv.targ,{duration:0.6, opacity:1, display:'block', ease:'power3.inOut' })
    gsap.to('#btn_' + lv.targ,{duration:0.6, opacity:0, display:'none', ease:'power3.inOut' })
    gsap.to('#' + lv.targ + '_bg_2',{duration:0.6, opacity:1, ease:'power3.inOut' })
    lv.targ = 'slat_3'
    gsap.to('#' + lv.targ,{duration:0.6, left:1822, ease:'power3.inOut' })
    gsap.to('#content_' + lv.targ,{duration:0.6, opacity:0, display:'none', ease:'power3.inOut' })
    gsap.to('#btn_' + lv.targ,{duration:0.6, opacity:1, display:'block', ease:'power3.inOut' })
    gsap.to('#' + lv.targ + '_bg_2',{duration:0.6, opacity:0, ease:'power3.inOut' })

    //EventBus.$emit('carrousel_pause')
    //EventBus.$emit('pause_crosses')
  }

  if(who == 3){
    lv.targ = 'slat_1'
    gsap.to('#content_' + lv.targ,{duration:0.6, opacity:0, display:'none', ease:'power3.inOut' })
    gsap.to('#btn_' + lv.targ,{duration:0.6, opacity:1, display:'block', ease:'power3.inOut' })
    gsap.to('#' + lv.targ + '_bg_2',{duration:0.6, opacity:0, ease:'power3.inOut' })
    lv.targ = '#canvas_wrap'
    //gsap.to(lv.targ,{delay: 0.6, duration:0.6, opacity:0, display:'none', ease:'power3.inOut' })
    lv.targ = '#hide_slat_1'
    gsap.to(lv.targ,{duration:0.6, opacity:1, display:'block', ease:'power3.inOut' })
    lv.targ = 'slat_2'
    gsap.to('#' + lv.targ,{duration:0.6, left:98, ease:'power3.inOut' })
    gsap.to('#content_' + lv.targ,{duration:0.6, opacity:0, display:'none', ease:'power3.inOut' })
    gsap.to('#btn_' + lv.targ,{duration:0.6, opacity:1, display:'block', ease:'power3.inOut' })
    gsap.to('#' + lv.targ + '_bg_2',{duration:0.6, opacity:0, ease:'power3.inOut' })
    lv.targ = 'slat_3'
    gsap.to('#' + lv.targ,{duration:0.6, left:196, ease:'power3.inOut' })
    gsap.to('#content_' + lv.targ,{duration:0.6, opacity:1, display:'block', ease:'power3.inOut' })
    gsap.to('#btn_' + lv.targ,{duration:0.6, opacity:0, display:'none', ease:'power3.inOut' })
    gsap.to('#' + lv.targ + '_bg_2',{duration:0.6, opacity:1, ease:'power3.inOut' })

    //EventBus.$emit('carrousel_pause')
    //EventBus.$emit('pause_crosses')
  }

  lv.slat_cur = who

}
function slat_start(){
  lv.slat_busy = true
  gsap.delayedCall(0.6,slat_done)
}
function slat_done(){
  lv.slat_busy = false
}


// ------------------------- fit -------------------------

function resize() {
  lv.factor_scale = getScaleFactor(1920, 1080);
  gsap.set('#wrap_main', { scale: lv.factor_scale})
}

function getScaleFactor(w, h) {
  lv.ww = window.innerWidth
  lv.wh = window.innerHeight
  lv.nsw = lv.ww / w;
  lv.nsh = lv.wh / h;
  if (lv.nsw <= lv.nsh) {
    lv.ns = lv.nsw;
  } else {
    lv.ns = lv.nsh;
  }
  if (lv.ns > 1) {
    lv.ns = 1;
  }
  return lv.ns;
}


</script>

<style>

</style>
