<template>
  <div class="menu_dev font-mono" id="menu_dev" v-if="menu_dev_show">
    <div class="cursor-pointer" @click="menu_toggle">
      <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
    </div>
    <div class="pt-2" v-if="menu_open">
      <div class="p-1 pl-0 cursor-pointer " @click="pointer_through">.pntr.</div>
      <div class="p-1 pl-0 cursor-pointer " @click="pause_cross">pause</div>
      <div class="p-1 pl-0 cursor-pointer hidden" @click="cross_raster">+rast</div>
      <div class="p-1 pl-0 cursor-pointer" @click="cross_age_years">+age (years)</div>
      <div class="p-1 pl-0 cursor-pointer" @click="cross_age_days">+age (days)</div>
      <div class="p-1 pl-0 cursor-pointer" @click="cross_deceased_months">+dec (months)</div>
      <div class="p-1 pl-0 cursor-pointer" @click="cross_deceased">+dec (days)</div>
      <div class="p-1 pl-0 cursor-pointer" @click="cross_origin">+ori</div>
      <div class="p-1 pl-0 cursor-pointer " @click="chart">.chrt.</div>
      <div class="p-1 pl-0 cursor-pointer " @click="cross_img_start">+imgnr+</div>
      <div class="p-1 pl-0 cursor-pointer " @click="cross_img_1">+img 1+</div>
      <div class="p-1 pl-0 cursor-pointer " @click="cross_img_2">+img 2+</div>
      <div class="p-1 pl-0 cursor-pointer " @click="cross_img_3">+img 3+</div>
      <div class="p-1 pl-0 cursor-pointer " @click="cross_img_4">+img 4+</div>
      <div class="p-1 pl-0 cursor-pointer " @click="cross_img_5">+img 5+</div>
      <div class="p-1 pl-0 cursor-pointer " @click="cinit">+cinit+</div>
      <div class="p-1 pl-0 cursor-pointer " @click="cpause">+cpause+</div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/modules/event_bus'

import gsap from 'gsap'

var lv = {}
lv.thisVue = null

lv.pntr = false
lv.chart = false

export default {
  name: "menu_dev",
  props: [],
  data() {
    return {
      menu_dev_show: true,
      menu_open: false
    }
  },
  mounted() {
    lv.thisVue = this


  },
  methods:{
    menu_toggle(){
      if(this.menu_open){
        this.menu_open = false
      }else{
        this.menu_open = true
      }
    },
    pointer_through(){
      if(lv.pntr){
        document.getElementById('wrap_slats').classList.remove('pointer-events-none')
        lv.pntr = false
      }else{
        document.getElementById('wrap_slats').classList.add('pointer-events-none')
        lv.pntr = true
      }
    },
    pause_cross(){
      EventBus.$emit('pause_cross')
    },
    chart(){
      if(!lv.chart){
        lv.chart = true
        gsap.set('#indicate_chart_dimensions',{display:'block'})
      }else{
        lv.chart = false
        gsap.set('#indicate_chart_dimensions',{display:'none'})
      }
    },
    cross_raster(){
      EventBus.$emit('cross_raster')
    },
    cross_age(){
      EventBus.$emit('cross_age')
    },
    cross_age_years(){
      EventBus.$emit('cross_age_years')
    },
    cross_age_days(){
      EventBus.$emit('cross_age_days')
    },
    cross_deceased(){
      EventBus.$emit('cross_deceased')
    },
    cross_deceased_months(){
      EventBus.$emit('cross_deceased_months')
    },
    cross_origin(){
      EventBus.$emit('cross_origin')
    },
    cross_img_cross(){
      EventBus.$emit('cross_img_cross')
    },
    cross_img_face1(){
      EventBus.$emit('cross_img_face1')
    },
    cross_img_name1(){
      EventBus.$emit('cross_img_name1')
    },
    cross_img_start(){
      EventBus.$emit('cross_img_start')
    },
    cross_img_1(){
      EventBus.$emit('cross_img_1')
    },
    cross_img_2(){
      EventBus.$emit('cross_img_2')
    },
    cross_img_3(){
      EventBus.$emit('cross_img_3')
    },
    cross_img_4(){
      EventBus.$emit('cross_img_4')
    },
    cross_img_5(){
      EventBus.$emit('cross_img_5')
    },
    cinit(){
      EventBus.$emit('cinit')
    },
    cpause(){
      EventBus.$emit('crosses_pause')
    }
  }
}
</script>

<style>

.menu_dev{
  position: fixed;
  top:0px;
  left:0px;
  color:#fff;
  background: rgba(45, 55, 72, 0.7);
  border: 1px solid rgba(255,255,255,0.2);
  padding:10px;
  font-size: 12px;
  font-weight: 300;
  font-family: 'Roboto Mono';
}

</style>

